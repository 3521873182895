import styles from './share-to-hub.module.scss';
import { Switch } from 'antd';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { isKnowledgeHubSharedEnabled } from 'shared/store/selectors/features.selector';
import { HELPDESK_URL } from 'shared/constants/assets.constants';
import classNames from 'classnames';

export function ShareToHub({
  name,
  value,
  setFieldValue,
  showHelpdesk = false,
  topBorder = false,
}) {
  const lang = useSelector(getLang('SHARE_TO_HUB'));

  const isSharedEnabled = useSelector(isKnowledgeHubSharedEnabled);
  if (!isSharedEnabled) return null;

  const onChange = checked => {
    setFieldValue(name, checked);
  };

  return (
    <div className={classNames(styles.root, { [styles.topBorder]: topBorder })}>
      <div className={styles.titleLinkWrapper}>
        <h6>{lang.SHARE_COMMUNITY}</h6>
        {showHelpdesk && (
          <a href={HELPDESK_URL} className={styles.link} target="_blank" rel="noreferrer">
            {lang.HELPDESK}
          </a>
        )}
      </div>
      <div className={styles.switch}>
        <Switch defaultChecked={value} onChange={onChange} />
        <span className={styles.switchLabel}>{lang.SHARE_ITEM}</span>
      </div>
    </div>
  );
}
