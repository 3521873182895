import React from 'react';
import { Lang, ROUTES, DATA_KEYS } from 'shared/constants';
import { Popup } from '../../../components/common';
import { AuthPage } from '../auth-page-wrapper';
import { useFormik } from 'formik';
import styles from './not-allowed.module.scss';
import { notAllowedSSchema } from 'shared/schemas/user.schemas';
import UserService from 'shared/services/user.service';
import { useHistory, useLocation } from 'react-router';
import { TextInput } from 'src/components/design-system/forms';
import { Button } from 'src/components/design-system/buttons';
import { Col, Row } from 'antd';
import { NavLink } from 'react-router-dom-v5-compat';
import { OAuthFooter } from '../oauth';
const { NOT_ALLOWED } = DATA_KEYS;

const NotAllowedPage = React.memo(({ lang }) => {
  const [error, setError] = React.useState(null);
  const [loading, setLoadingState] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const email = location?.state?.email || '';

  const contactUs = React.useCallback(async values => {
    setError('');
    setLoadingState(true);

    let res = await UserService.contactUs(values);

    setLoadingState(false);

    if (res?.error) {
      setError(res.error);
    } else {
      setSuccess(true);
    }
  }, []);

  const onFinish = () => {
    history.push(ROUTES.WELCOME);
  };

  const { handleChange, handleBlur, handleSubmit, values, errors, touched, isValid } = useFormik({
    validationSchema: notAllowedSSchema,
    initialValues: { email },
    onSubmit: contactUs,
  });

  return (
    <AuthPage title={lang.HEADER} content={lang.CONTENT}>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col span={24}>
            <TextInput
              type="email"
              name="email"
              value={values.email}
              error={errors.email}
              touched={touched.email}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus={true}
              placeholder={lang.EMAIL_PLACEHOLDER}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col md={12} xs={24}>
            <TextInput
              type="text"
              name="fullName"
              value={values.fullName}
              error={errors.fullName}
              touched={touched.fullName}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus={true}
              placeholder={lang.FULL_NAME_PLACEHOLDER}
            />
          </Col>
          <Col md={12} xs={24}>
            <TextInput
              type="text"
              name="phone"
              value={values.phone}
              error={errors.phone}
              touched={touched.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus={true}
              placeholder={lang.PHONE_PLACEHOLDER}
            />
          </Col>
        </Row>

        <Button disabled={!isValid} htmlType="submit" loading={loading}>
          {lang.SEND_MY_DETAILS}
        </Button>

        <p className={styles.redirect}>
          <NavLink to={ROUTES.WELCOME}>Try login in with a different email</NavLink>
        </p>

        <div>{error && <span>{error}</span>}</div>
      </form>
      <OAuthFooter loading={loading} />
      <Popup isOpen={success}>
        <h1>{lang.SUCCESS_TITLE}</h1>
        <p className={styles.successContent}>{lang.SUCCESS_CONTENT}</p>
        <Button onClick={onFinish} type="primary" data-key={NOT_ALLOWED.ACCEPT_BUTTON}>
          {lang.SUCCESS_BUTTON}
        </Button>
      </Popup>
    </AuthPage>
  );
});

NotAllowedPage.defaultProps = {
  lang: Lang.NOT_ALLOWED,
};

export { NotAllowedPage };
