export const ASSET_TYPES = {
  DOCUMENT: 'document',
  PDF: 'pdf',
  IMAGE: 'image',
  VIDEO: 'video',
} as const;

type AssetType = (typeof ASSET_TYPES)[keyof typeof ASSET_TYPES];

export const ALLOWED_MIME_TYPES = {
  avi: 'video/x-msvideo',
  mov: 'video/quicktime',
  csv: 'text/csv',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  gif: 'image/gif',
  mp4: 'video/mp4',
  pdf: 'application/pdf',
  png: 'image/png',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  webm: 'video/webm',
  webp: 'image/webp',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pot: 'application/vnd.ms-powerpoint',
  pps: 'application/vnd.ms-powerpoint',
  potx: 'application/vnd.openxmlformats-officedocument.presentationml.template',
  ppsx: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  xlt: 'application/vnd.ms-excel',
  xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  m4v: 'video/x-m4v',
  m4a: 'audio/mp4',
};

export const SUPPORTED_IMG_FORMATS: { [key: string]: string[] } = Object.values(ALLOWED_MIME_TYPES)
  .filter(value => value.startsWith('image/'))
  .reduce((acc, value) => ({ ...acc, [value]: [] }), {});

export const MOV_FILE_EXTENSION = 'mov';

const TO_MB_FACTOR = 1024 * 1024;

export const ASSET_TYPES_LIMITS = {
  [ASSET_TYPES.PDF]: 1000 * TO_MB_FACTOR, // 1GB
  [ASSET_TYPES.IMAGE]: 100 * TO_MB_FACTOR, // 100MB
  [ASSET_TYPES.VIDEO]: 2000 * TO_MB_FACTOR, // 2GB
  [ASSET_TYPES.DOCUMENT]: 1000 * TO_MB_FACTOR, // 1GB
};

export const ASSET_TYPES_BY_FILE_EXTENSION: { [key: string]: AssetType } = {
  avi: ASSET_TYPES.VIDEO,
  mov: ASSET_TYPES.VIDEO,
  csv: ASSET_TYPES.DOCUMENT,
  doc: ASSET_TYPES.DOCUMENT,
  docx: ASSET_TYPES.DOCUMENT,
  jpeg: ASSET_TYPES.IMAGE,
  jpg: ASSET_TYPES.IMAGE,
  gif: ASSET_TYPES.IMAGE,
  mp4: ASSET_TYPES.VIDEO,
  pdf: ASSET_TYPES.PDF,
  png: ASSET_TYPES.IMAGE,
  ppt: ASSET_TYPES.DOCUMENT,
  pptx: ASSET_TYPES.DOCUMENT,
  webm: ASSET_TYPES.VIDEO,
  webp: ASSET_TYPES.IMAGE,
  xls: ASSET_TYPES.DOCUMENT,
  xlsx: ASSET_TYPES.DOCUMENT,
  pot: ASSET_TYPES.DOCUMENT,
  pps: ASSET_TYPES.DOCUMENT,
  potx: ASSET_TYPES.DOCUMENT,
  ppsx: ASSET_TYPES.DOCUMENT,
  xlsb: ASSET_TYPES.DOCUMENT,
  xlsm: ASSET_TYPES.DOCUMENT,
  xlt: ASSET_TYPES.DOCUMENT,
  xltx: ASSET_TYPES.DOCUMENT,
  m4v: ASSET_TYPES.VIDEO,
  m4a: ASSET_TYPES.VIDEO,
};

export const HELPDESK_URL = 'https://help.c8health.com';

export const SUPPORTED_FILES_HELPDESK_LINK = `${HELPDESK_URL}/article/215-supported-file-types`;
