import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import styles from './contact-details-form.module.scss';
import { SelectBox, TextInput } from '../design-system/forms';
import { useProfileOptions } from 'shared/hooks/profile.hooks';
import { Lang } from 'shared/constants';
import { Button } from '../design-system/buttons';

const CONTACT_SCHEMA = Yup.object().shape({
  email: Yup.string().email(),
  firstName: Yup.string().nullable(),
  lastName: Yup.string(),
  phone: Yup.string().nullable(),
  pager: Yup.string().nullable(),
  cisco: Yup.string().nullable(),
  department: Yup.string(),
});

const ContactDetailsFormComponent = React.memo(function ContactDetailsFormComponent({
  contact,
  lang = Lang.CONTACT_SCREEN,
  createContact,
  updateContact,
  onDeleteContact,
  onFinish,
  openNotification,
  canEdit = false,
  workspaces,
  selectedWorkspaceId,
}) {
  const isNewContact = !contact?.id;
  const [error, setError] = React.useState(null);

  const update = useCallback(
    async data => {
      let res;
      if (isNewContact) {
        res = await createContact({ contact: data });
      } else {
        res = await updateContact({ id: contact.id, contact: data });
      }

      if (res?.error) {
        setError(res?.error);
      }
      if (res?.errors) {
        openNotification({
          message: `This contact already exists: ${res.errors?.email}`,
          type: 'error',
        });
      }
      if (res?.contact) {
        const createdContact =
          res?.contact.email || `${res?.contact.firstName} ${res?.contact.lastName || ''}`;
        openNotification({
          message: isNewContact
            ? `${lang.NOTIFICATION_CREATE_SUCCESS}: ${createdContact}`
            : `${lang.NOTIFICATION_UPDATE_SUCCESS}: ${createdContact}`,
        });
        setError(null);
        onFinish();
      }
    },
    [isNewContact, createContact, updateContact, contact, onFinish, openNotification, lang],
  );

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    isValid,
  } = useFormik({
    validationSchema: CONTACT_SCHEMA,
    initialValues: {
      ...contact,
      ...contact?.professional,
      workspaceId: contact?.workspaceId ?? selectedWorkspaceId,
    },
    onSubmit: update,
  });

  const handleDeleteContact = useCallback(() => {
    onDeleteContact(contact);
  }, [contact, onDeleteContact]);

  const { departmentOptions } = useProfileOptions({
    profession: values.profession,
    role: values.role,
    department: values.department,
  });

  return (
    <div className={styles.form}>
      <div>
        <TextInput
          label={lang.EMAIL}
          placeholder={lang.EMAIL}
          name="email"
          value={values.email}
          error={errors.email}
          touched={touched.email}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!canEdit}
        />
        <TextInput
          label={lang.FIRST_NAME}
          placeholder={lang.FIRST_NAME}
          name="firstName"
          value={values.firstName}
          error={errors.firstName}
          touched={touched.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!canEdit}
        />
        <TextInput
          label={lang.LAST_NAME}
          placeholder={lang.LAST_NAME}
          name="lastName"
          value={values.lastName}
          error={errors.lastName}
          touched={touched.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!canEdit}
        />
        <TextInput
          label={lang.PHONE}
          placeholder={lang.PHONE}
          name="phone"
          value={values.phone}
          error={errors.phone}
          touched={touched.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!canEdit}
        />
        <TextInput
          label={lang.PAGER}
          placeholder={lang.PAGER}
          name="pager"
          value={values.pager}
          error={errors.pager}
          touched={touched.pager}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!canEdit}
        />
        <TextInput
          label={lang.CISCO}
          placeholder={lang.CISCO}
          name="cisco"
          value={values.cisco}
          error={errors.cisco}
          touched={touched.cisco}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!canEdit}
        />
        <SelectBox
          label={lang.DEPARTMENT}
          placeholder={lang.DEPARTMENT}
          name="department"
          value={values.department}
          error={errors.department}
          onChange={setFieldValue}
          options={departmentOptions}
          disabled={!departmentOptions || !canEdit}
        />
        {workspaces.length > 1 && (
          <SelectBox
            label={lang.WORKSPACE}
            placeholder={lang.WORKSPACE}
            name="workspaceId"
            value={values.workspaceId}
            error={errors.workspaceId}
            onChange={setFieldValue}
            options={workspaces.map(workspace => ({ id: workspace.id, value: workspace.name }))}
            disabled={!canEdit}
          />
        )}
      </div>

      {canEdit && (
        <div className={styles.buttonsContainer}>
          {error && <div className={styles.error}>{error}</div>}
          <Button onClick={handleSubmit} type="primary" size="large" disabled={!isValid}>
            {isNewContact ? lang.ADD_NEW_CONTACT : lang.SAVE}
          </Button>
          {!isNewContact && (
            <Button onClick={handleDeleteContact} type="danger" size="large">
              {lang.DELETE}
            </Button>
          )}
        </div>
      )}
    </div>
  );
});

export { ContactDetailsFormComponent };
