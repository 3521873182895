import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  ChecklistEntityIcon,
  DashboardEntityIcon,
  CompendiumEntityIcon,
  KnowledgeBaseEntityIcon,
  BookmarkEntityIcon,
  ContactsEntityIcon,
  RotationsEntityIcon,
  KnowledgeHubEntityIcon,
  CommunicationCenterEntityIcon,
} from 'src/images';
import { ROUTES, DATA_KEYS } from 'shared/constants';
import styles from './sidebar.module.scss';
import classnames from 'classnames';
import {
  isContactsFeatureEnabled,
  isRotationsEnabled,
} from 'shared/store/selectors/features.selector';
import { getLang } from 'shared/store/selectors/lang.selector';
import { hasPermissionsToAccessKnowledgeHub } from 'shared/store/selectors/iam.selector';
import { isCollaborator } from 'shared/store/selectors/auth.selector';
const { HOME_PAGE } = DATA_KEYS;

const MenuItem = React.memo(
  ({ path, title, icon, sub = [], exact = true, hide = false, ...props }) => {
    const to = _.isArray(path) ? path[0] : path;

    if (hide) return null;

    return (
      <NavLink
        exact={exact}
        className={styles.menuRoot}
        activeClassName={styles.active}
        key={path}
        to={to}
        {...props}
      >
        <img src={icon} alt={title} className={styles.icon} />
        {title}
      </NavLink>
    );
  },
);

export function Sidebar() {
  const isContactsEnabled = useSelector(isContactsFeatureEnabled);
  const isRotationsEnabledState = useSelector(isRotationsEnabled);
  const isKnowledgeHubVisible = useSelector(hasPermissionsToAccessKnowledgeHub);
  const lang = useSelector(getLang('SIDEBAR'));
  const hasCollaboratorPermission = useSelector(isCollaborator);

  const routes = React.useMemo(
    () => [
      {
        path: ROUTES.HOME,
        icon: DashboardEntityIcon,
        title: lang.HOME,
      },
      {
        path: ROUTES.FAVORITES,
        icon: BookmarkEntityIcon,
        title: lang.FAVORITES,
      },
      {
        path: ROUTES.CONTACTS,
        icon: ContactsEntityIcon,
        title: lang.CONTACTS,
        hide: !isContactsEnabled,
      },
      {
        path: ROUTES.ADMISSION,
        icon: ChecklistEntityIcon,
        exact: false,
        title: lang.ADMISSION,
      },
      {
        path: ROUTES.LABS,
        icon: CompendiumEntityIcon,
        exact: false,
        title: lang.LAB,
      },
      {
        path: [ROUTES.KNOWLEDGE, ROUTES.CONTENT_ITEM],
        exact: false,
        icon: KnowledgeBaseEntityIcon,
        title: lang.ROOT,
        'data-key': HOME_PAGE.KNOWLEDGE,
      },
      {
        path: ROUTES.COMMUNICATION_CENTER,
        icon: CommunicationCenterEntityIcon,
        title: lang.COMMUNICATION_CENTER,
        hide: !hasCollaboratorPermission,
      },
      {
        path: ROUTES.ROTATIONS,
        exact: false,
        icon: RotationsEntityIcon,
        title: lang.ROTATIONS,
        hide: !isRotationsEnabledState,
      },
      {
        path: ROUTES.KNOWLEDGE_HUB,
        icon: KnowledgeHubEntityIcon,
        title: lang.KNOWLEDGE_HUB,
        hide: !isKnowledgeHubVisible,
      },
    ],
    [
      isContactsEnabled,
      isRotationsEnabledState,
      lang,
      isKnowledgeHubVisible,
      hasCollaboratorPermission,
    ],
  );

  return (
    <div className={classnames(styles.sidebar, 'new-design-system')} data-key={HOME_PAGE.SIDEBAR}>
      {routes.map(item => (
        <MenuItem key={item.path} {...item} />
      ))}
    </div>
  );
}
