import React from 'react';
import styles from './contacts.module.scss';
import { ScreenTitle } from '../../../components/design-system/titles';
import classnames from 'classnames';
import { NoContent } from '../../../components/common';
import { Input } from '../../../components/core';
import { searchOnContactList } from 'shared/store/selectors/search.selector';
import { ROUTES, INTERNAL_ROUTING, EVENTS } from 'shared/constants';
import { UserDetailsComponent } from '../../../components/user-details';
import { useHistory, useLocation } from 'react-router';
import { ContactDetailsComponent } from '../../../components/contact-details';
import { useCallback } from 'react';
import analyticsService from '../../../helpers/analytics.service';
import { Button, notification } from 'antd';
import { Lang } from 'shared/constants';
import { ConfirmationPopupComponent } from '../../../components/common/confirmation-popup';
import { PlusCircleTwoTone } from '@ant-design/icons';
import { FixedSizeList } from 'react-window';
import { ContactRow } from './contacts-renderers/contact-row.component';
import { ContactGroup } from './contacts-renderers/contact-group.component';

export const ITEM_SIZE = 74;
const HEADER_HEIGHT = 200;

const ContactsComponent = React.memo(function ContactsComponent({
  lang = Lang.CONTACT_SCREEN,
  contacts,
  departmentsLang,
  deleteContact,
  selectedWorkspaceId,
  workspaces,
}) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [details, setDetails] = React.useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [deleteContactEntity, setDeleteContactEntity] = React.useState(null);
  const history = useHistory();
  const { search } = useLocation();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const id = urlParams.get(INTERNAL_ROUTING.QUERY_PARAMS.CONTACTS);
    if (id) {
      const contact = contacts.find(contact => contact.id === id);
      setDetails(contact);
    } else {
      setDetails(null);
    }
  }, [search, contacts]);

  const onSearch = e => {
    setSearchTerm(e?.target?.value);
  };

  const openNotification = useCallback(
    ({ message, type = 'success' }) => {
      api[type]({
        message,
        placement: 'bottomRight',
        duration: 10,
      });
    },
    [api],
  );

  const openDetails = id => {
    analyticsService.track(EVENTS.CONTACT.OPEN_DETAILS, { id });
    history.push(`${ROUTES.CONTACTS}?${INTERNAL_ROUTING.QUERY_PARAMS.CONTACTS}=${id}`);
  };

  const closeDetails = useCallback(() => {
    history.push(ROUTES.CONTACTS);
  }, [history]);

  const onDeleteContact = useCallback(contact => setDeleteContactEntity(contact), []);
  const doDeleteContact = useCallback(async () => {
    const res = await deleteContact({ id: deleteContactEntity.id });

    if (!res?.error) {
      openNotification({ message: lang.NOTIFICATION_DELETE_SUCCESS });
      setDeleteContactEntity(null);
      closeDetails();
    }
  }, [deleteContactEntity, deleteContact, openNotification, lang, closeDetails]);

  const cancelDeleteContact = useCallback(() => setDeleteContactEntity(null), []);

  // filter by workspace
  const filterByWorkspace = ({ list = [], workspaceId }) => {
    if (!workspaceId) {
      return list?.filter(item => item.isUser);
    }

    return list?.filter(item => item?.workspaceId === workspaceId);
  };

  const filteredContacts = contacts?.filter(({ firstName, lastName, email, professional }) =>
    searchOnContactList(
      {
        firstName,
        lastName,
        email,
        department: professional?.department ? departmentsLang[professional.department] : undefined,
      },
      searchTerm,
    ),
  );

  const renderContacts = () => {
    if (!filteredContacts?.length) {
      return <NoContent text={lang.NOT_FOUND} />;
    }

    const renderHeader = () => (
      <div className={classnames([styles.contactRow, styles.topRow])}>
        <div className={classnames([styles.nameWrapper, styles.tableHeader])}>{lang.NAME}</div>
        <div className={classnames([styles.phone, styles.tableHeader])}>{lang.PHONE}</div>
        <div className={classnames([styles.pager, styles.tableHeader])}>{lang.PAGER}</div>
        <div className={classnames([styles.pager, styles.tableHeader])}>{lang.CISCO}</div>
        <div className={classnames([styles.email, styles.tableHeader])}>{lang.EMAIL}</div>
        <div className={classnames(styles.tableHeader)}></div>
      </div>
    );

    if (workspaces.length === 1) {
      return (
        <>
          {renderHeader()}
          <FixedSizeList
            height={window.innerHeight - HEADER_HEIGHT}
            itemCount={filteredContacts.length}
            itemSize={ITEM_SIZE}
            width={'100%'}
          >
            {({ index, style }) => {
              return <ContactRow {...{ index, style, list: filteredContacts, openDetails }} />;
            }}
          </FixedSizeList>
        </>
      );
    }

    const workspaceList = [
      { id: null, name: lang.USERS },
      ...workspaces.sort(({ id }) => (id === selectedWorkspaceId ? -1 : 1)),
    ];

    return (
      <>
        {renderHeader()}
        {workspaceList.map(({ id }) => (
          <ContactGroup
            workspaceId={id}
            list={filterByWorkspace({ list: filteredContacts, workspaceId: id })}
            label={workspaceList.find(workspace => workspace.id === id)?.name}
            openDetails={openDetails}
            expanded={id === selectedWorkspaceId}
          />
        ))}
      </>
    );
  };

  const renderDetails = () => (
    <>
      {contextHolder}
      {details?.isUser ? (
        <UserDetailsComponent
          user={details}
          close={closeDetails}
          open={!!details}
          lang={lang}
          openNotification={openNotification}
          canEdit={false}
        />
      ) : (
        <ContactDetailsComponent
          contact={details}
          close={closeDetails}
          open={!!details}
          lang={lang}
          openNotification={openNotification}
          onDeleteContact={onDeleteContact}
        />
      )}
    </>
  );

  return (
    <>
      {renderDetails()}
      <div className={styles.root}>
        <div className={styles.header}>
          <ScreenTitle
            title={`${lang.TITLE} ${contacts?.length > 0 ? `(${contacts?.length})` : ''}`}
            className={styles.noPadding}
          />
          <Button
            size="large"
            type="primary"
            className={styles.button}
            ghost={true}
            icon={<PlusCircleTwoTone />}
            onClick={() => setDetails({})}
          >
            {lang.ADD_NEW_CONTACT}
          </Button>
          <Input className={styles.input} onChange={onSearch} placeholder={'Type to filter'} />
        </div>
        {renderContacts()}
      </div>
      {deleteContactEntity && (
        <ConfirmationPopupComponent
          title={lang.DELETE_CONTACT_POPUP_TITLE}
          message={lang.DELETE_CONTACT_POPUP_TEXT.replace(
            '{name}',
            `${deleteContactEntity?.firstName} ${deleteContactEntity?.lastName || ''}`,
          )}
          confirmMessage={lang.DELETE_CONTACT_POPUP_CONFIRM}
          dismissMessage={lang.DELETE_CONTACT_POPUP_CANCEL}
          onConfirm={doDeleteContact}
          onDismiss={cancelDeleteContact}
        />
      )}
    </>
  );
});

export { ContactsComponent };
