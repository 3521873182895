import React, { useEffect } from 'react';
import { Lang, KNOWLEDGE_TYPES, ROUTES, ENTITY_TYPES } from 'shared/constants';
import { useFormik } from 'formik';
import { DatePicker, Loader, FileUpload } from '../../core';
import analyticsService from '../../../helpers/analytics.service';
import { EVENTS } from 'shared/constants/analytics';
import sharedStyles from '../knowledge-content.module.scss';
import styles from './knowledge-content-editor.module.scss';
import { ConfirmationPopupComponent } from '../../common/confirmation-popup/confirmation-popup.component';
import { TableOfContents } from '../components/table-of-content';
import { useHistory } from 'react-router';
import { LabelsSelector } from '../../labels/labels-selector';
import { useHeadingsData } from '../components/table-of-content/table-of-contents.hooks';
import { useDispatch } from 'src/store/use-dispatch';
import { convertAssetToPdf } from 'shared/store/actions/knowledge.items.actions';
import { useSmartFormat } from './hooks/use-smart-format';
import { ASSET_TYPES } from 'shared/constants/assets.constants';
import uploadService from 'shared/services/upload.service';
import { KNOWLEDGE_ITEM_SCHEMA } from '../schema/knowledge-item.schema';
import { KnowledgeViewLayout } from '../layout/knowledge-view-layout';
import {
  RenderHeaderActions,
  RenderExpirationDate,
  RenderKeywordsSelector,
  RenderNotifyTeam,
  RenderEditor,
} from './components/';
import { RenderSection } from '../components';
import { RenderTags } from './components/render-tags';
import { TextInput, TextAreaInput } from 'src/components/design-system/forms';
import { RenderBack } from '../components/render-back';
import { ContributorsSelector } from './components';
import { useSelector } from 'react-redux';
import { getCategoryById } from 'shared/store/selectors/knowledge.selector';
import { MultipleOwnersSelector } from 'src/components/owners/owners-selector/multiple-owners-selector';

const KnowledgeContentEditorComponent = React.memo(function KnowledgeContentEditorComponent({
  lang,
  knowledge,
  categoryId,
  createKnowledgeItem,
  updateKnowledgeItem,
  uploadFile,
  onFinish,
  type,
  fileName,
  labelsViability,
  currentWorkspaceId,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoadingState] = React.useState(false);
  const [parentId] = React.useState(categoryId);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [editorFocused, setEditorFocused] = React.useState(false);

  const category = useSelector(({ knowledge }) => getCategoryById(knowledge, { id: parentId }));

  /**
   * @typedef {undefined | { knowledgeId: string }} DuplicatedAssetState
   */
  /**
   * @type {[DuplicatedAssetState, React.Dispatch<React.SetStateAction<DuplicatedAssetState>>]}
   */
  const [duplicatedAsset, setDuplicatedAsset] = React.useState();

  const isNew = !knowledge.id;
  const isParentShared = category?.shared?.isShared;

  const onSubmit = React.useCallback(
    async data => {
      setLoadingState(true);
      const { specialty, subspecialty, contentType, targetAudience, ...restData } = data;

      const itemData = {
        ...restData,
        tags: { specialty, subspecialty, contentType, targetAudience },
        content: data?.content?.replace(/[\r\n]+/g, ''),
      };
      if (itemData.expiresAt === null) {
        delete itemData.expiresAt;
      }
      if (itemData.link === undefined) {
        delete itemData.link;
      }
      if (itemData.shareToHub === undefined) {
        delete itemData.shareToHub;
      }

      let knowledgeId = knowledge.id;

      if (isNew) {
        // New item created
        itemData.categoryId = parentId;
        if (isParentShared) {
          itemData.isParentShared = true;
        }
        const res = await createKnowledgeItem(itemData);
        knowledgeId = res?.id;

        const EVENT_DATA = {
          id: knowledgeId,
          title: itemData?.title,
          ownerIds: itemData?.ownerIds,
          type: itemData?.type,
          specialty: itemData?.tags?.specialty,
          subspecialty: itemData?.tags?.subspecialty,
          contentType: itemData?.tags?.contentType,
          targetAudience: itemData?.tags?.targetAudience,
        };
        analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.CREATE_KNOWLEDGE_ITEM, EVENT_DATA);
        analyticsService.track(EVENTS.CONTENT.UPLOAD, {
          ...EVENT_DATA,
          entityType: ENTITY_TYPES.knowledge,
        });
        history.push(ROUTES.KNOWLEDGE_ITEMS.replace(':id', knowledgeId));
      } else {
        //Update knowledge by id
        itemData.id = knowledge.id;

        if (parentId && parentId !== categoryId) {
          itemData.categoryId = parentId;
        }

        const EVENT_DATA = {
          id: itemData?.id,
          title: itemData?.title,
          ownerIds: itemData?.ownerIds,
          type: itemData?.type,
          specialty: itemData?.tags?.specialty,
          subspecialty: itemData?.tags?.subspecialty,
          contentType: itemData?.tags?.contentType,
          targetAudience: itemData?.tags?.targetAudience,
        };

        analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.UPDATE_KNOWLEDGE_ITEM, EVENT_DATA);
        analyticsService.track(EVENTS.CONTENT.UPDATE, {
          ...EVENT_DATA,
          entityType: ENTITY_TYPES.knowledge,
        });

        await updateKnowledgeItem(itemData);
      }

      onFinish(knowledgeId);
      setLoadingState(false);
    },
    [
      knowledge.id,
      isNew,
      onFinish,
      parentId,
      createKnowledgeItem,
      history,
      categoryId,
      updateKnowledgeItem,
      isParentShared,
    ],
  );

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    values,
    errors,
    touched,
    initialValues,
  } = useFormik({
    validationSchema: KNOWLEDGE_ITEM_SCHEMA,
    initialValues: {
      type,
      ...knowledge,
      ...knowledge.tags,
      contributors: knowledge.contributors || [],
      fileUpdatedAt: new Date().toISOString(),
      expiresAt: knowledge.expiresAt || new Date(Date.now() + 3.154e10).toISOString(),
      isSendNotification: false,
      notifyOnWorkingHours: false,
      recipientsTags: {},
      workspaceIds: [currentWorkspaceId],
      shareToHub: knowledge?.shared?.isShared || (isParentShared && isNew ? true : undefined),
    },
    onSubmit,
  });

  const smartFormat = useSmartFormat({ knowledgeId: knowledge?.id, knowledgeTitle: values.title });

  const documentContent = values.content;
  const isContent = values.type === KNOWLEDGE_TYPES.CONTENT;
  const isUploadedFile =
    values.type === KNOWLEDGE_TYPES.DOCUMENT ||
    values.type === KNOWLEDGE_TYPES.PDF ||
    values.type === KNOWLEDGE_TYPES.VIDEO ||
    values.type === KNOWLEDGE_TYPES.IMAGE;

  /*
   * Sets default title based on content's first heading or file name.
   * Only applies to the initial result of convertion.
   * If content is edited later, default title shouldn't change.
   */
  const { headings } = useHeadingsData({ content: knowledge?.content });

  useEffect(() => {
    let itemTitle = knowledge?.title;

    if (itemTitle) {
      return;
    }

    if (isContent) {
      itemTitle = headings[0]?.text;
    }
    if (isUploadedFile) {
      itemTitle = fileName?.slice(0, fileName?.lastIndexOf('.'));
    }

    setFieldValue('title', itemTitle);
  }, [knowledge?.title, fileName, headings, setFieldValue, isContent, isUploadedFile]);

  const openConfirmationPopup = React.useCallback(() => {
    setShowConfirmation(true);
  }, [setShowConfirmation]);

  const closeConfirmationPopup = React.useCallback(() => {
    setShowConfirmation(false);
  }, [setShowConfirmation]);

  const dismiss = () => {
    setShowConfirmation(false);
    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.CLOSE_EDIT_MODE, {
      id: knowledge?.id,
      title: knowledge?.title,
      ownerIds: knowledge?.ownerIds,
      type,
      action: 'dismiss',
      state: isNew ? 'new' : 'edit',
    });

    onFinish(knowledge.id);
  };

  const resetField = React.useCallback(
    field => {
      const newValues = { values };
      delete newValues.values[field];
      resetForm(newValues);
    },
    [values, resetForm],
  );

  const onUploadFile = React.useCallback(
    async file => {
      setLoadingState(true);

      let newAsset = await uploadFile({ file });

      // finalize upload
      const finalizeRes = await uploadService.finalizeUploadFile({ assetId: newAsset?.assetId });

      if (finalizeRes?.error) {
        setLoadingState(false);
        return;
      }

      // Check for duplicates
      if (finalizeRes?.duplicate) {
        setLoadingState(false);
        analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.DUPLICATION_DETECTED);
        setDuplicatedAsset(finalizeRes?.duplicate);
        return;
      }

      // convert asset to pdf
      const pdfAsset = await dispatch(convertAssetToPdf({ assetId: newAsset?.assetId }));

      // asset was converted to pdf
      if (pdfAsset) {
        newAsset = {
          ...newAsset,
          url: pdfAsset?.convertedFilePath,
          type: pdfAsset?.newFormat,
        };
      }

      if (
        isContent &&
        (newAsset.type === ASSET_TYPES.DOCUMENT || newAsset.type === ASSET_TYPES.PDF)
      ) {
        const smartFormatConversionResult = await smartFormat.convertToSmartFormat({
          knowledgeId: knowledge?.id,
          knowledgeTitle: values.title,
          asset: newAsset,
        });

        if (smartFormatConversionResult.data) {
          setLoadingState(false);

          setFieldValue('type', KNOWLEDGE_TYPES.CONTENT);
          setFieldValue('content', smartFormatConversionResult.data);
          setFieldValue('link', newAsset?.url);

          return;
        }
      }

      setLoadingState(false);

      setFieldValue('type', newAsset.type);
      setFieldValue('content', undefined);
      setFieldValue('link', newAsset?.url);
    },
    [uploadFile, dispatch, smartFormat, knowledge?.id, values.title, isContent, setFieldValue],
  );

  const onDuplicatedAssetLink = async () => {
    setLoadingState(true);

    // Reject the duplicated item - notify the server to remove
    await uploadService.rejectDuplicatedItem({ assetId: duplicatedAsset.knowledgeId });

    /*
      User might have invalid fields, let's just skip to saving the last accepted knowledge item properties
      plus the new type and its information.

      This happens because internal link knowledge items do not have a preview or an editor screen,
      they only redirect, so that means we have to close the editor screen and redirect to the new link pointing to the existing item.
    */
    onSubmit({
      ...initialValues,
      content: '',
      type: KNOWLEDGE_TYPES.LINK,
      link: `${window.location.origin}${ROUTES.KNOWLEDGE_ITEMS.replace(
        ':id',
        duplicatedAsset.knowledgeId,
      )}`,
    });
  };

  const renderTableOfContents = () => (
    <TableOfContents lang={lang} itemId={knowledge?.id} content={documentContent} readOnly />
  );

  const renderHeader = () => {
    return (
      <div className={sharedStyles.contentHeader}>
        <div className={styles.header}>
          <RenderBack close={openConfirmationPopup} className={styles.back} />
          <TextAreaInput
            name="title"
            value={values.title}
            error={errors.title}
            touched={touched.title?.toString()}
            onChange={handleChange}
            onBlur={handleBlur}
            autoSize={{ minRows: 1, maxRows: 2 }}
            placeholder={lang.ITEM_NAME_PLACEHOLDER}
            size="large"
            className={sharedStyles.titleInput}
            bordered={false}
          />
        </div>
        <TextInput
          name="subtitle"
          value={values.subtitle}
          error={errors.subtitle}
          touched={touched.subtitle}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          placeholder={lang.ITEM_DESCRIPTION_PLACEHOLDER}
          bordered={false}
          className={sharedStyles.subtitleInput}
        />
      </div>
    );
  };

  const renderRightPanel = () => {
    return (
      <div className={styles.rightPanelWrapper}>
        <RenderNotifyTeam values={values} errors={errors} setFieldValue={setFieldValue} />

        <section className={sharedStyles.knowledgeActions}>
          <RenderHeaderActions
            knowledgeId={knowledge.id}
            isLoading={isLoading}
            editorFocused={editorFocused}
            values={values}
            errors={errors}
            initialValues={initialValues}
            openConfirmationPopup={openConfirmationPopup}
            onRevertConversion={() => {
              setFieldValue('content', '');
              setFieldValue('type', KNOWLEDGE_TYPES.PDF);
            }}
          />
        </section>

        <RenderSection header={lang.VALIDATED_AT}>
          <div className={styles.inlineInput}>
            <DatePicker
              name="fileUpdatedAt"
              value={values.fileUpdatedAt}
              error={errors.fileUpdatedAt}
              touched={touched.fileUpdatedAt}
              onChange={setFieldValue}
              className={styles.smallWrapper}
            />
          </div>
        </RenderSection>

        <RenderSection header={lang.OWNER}>
          <label>
            {errors.ownerIds && <span className={styles.error}>({errors.ownerIds})</span>}
          </label>
          <MultipleOwnersSelector
            value={values.ownerIds}
            placeholder={lang.OWNER_PLACEHOLDER}
            name="ownerIds"
            onChange={setFieldValue}
            borderRadius={4}
            bordered
          />
        </RenderSection>

        <RenderSection header={lang.CONTRIBUTORS}>
          <ContributorsSelector
            onChange={v => setFieldValue('contributors', v)}
            error={errors.contributors}
            value={values.contributors}
            bordered
            borderRadius={4}
          />
        </RenderSection>

        <RenderTags
          selectedValues={values}
          errors={errors}
          onChange={setFieldValue}
          resetField={resetField}
        />

        <RenderKeywordsSelector
          setFieldValue={setFieldValue}
          keywords={values?.keywords}
          knowledgeKeywords={knowledge?.keywords}
        />

        {labelsViability && (
          <RenderSection header={lang.LABELS}>
            <LabelsSelector
              value={values.labels}
              error={errors.labels}
              placeholder={lang.LABELS_PLACEHOLDER}
              name="labels"
              onChange={setFieldValue}
              wrapperClass={styles.tagsWrapper}
            />
          </RenderSection>
        )}

        <RenderExpirationDate
          setFieldValue={setFieldValue}
          name="expiresAt"
          value={values.expiresAt}
          error={errors.expiresAt}
          touched={touched.expiresAt}
        />

        <RenderSection header={lang.FEATURED_IMAGE}>
          <FileUpload
            name="thumbnail"
            onChange={setFieldValue}
            accept={{ 'image/*': [] }}
            wrapperClass={sharedStyles.thumbnailEditor}
            value={values.thumbnail}
            preview
            allowClear
          />
        </RenderSection>

        <div className={sharedStyles.footer}>
          {isLoading && <Loader />}
          {!isNew && <span className={sharedStyles.itemId}>Id: {knowledge?.id}</span>}
        </div>
      </div>
    );
  };

  return (
    <form className={styles.rootForm} onSubmit={handleSubmit}>
      <KnowledgeViewLayout
        hideLeftPanel={!(isContent && values?.content)}
        renderLeftPanel={renderTableOfContents}
        renderHeader={renderHeader}
        renderRightPanel={renderRightPanel}
        knowledgeId={knowledge.id}
      >
        <div className={styles.content}>
          <RenderEditor
            knowledgeId={knowledge?.id}
            isLoading={isLoading}
            values={values}
            errors={errors}
            touched={touched}
            duplicatedAsset={duplicatedAsset}
            onDuplicatedAssetCancel={() => {
              setDuplicatedAsset(undefined);
            }}
            onDuplicatedAssetLink={onDuplicatedAssetLink}
            onFileChange={onUploadFile}
            onContentChange={content => {
              setFieldValue('content', content);
            }}
            setLoadingState={setLoadingState}
            setEditorFocused={setEditorFocused}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </div>
        {showConfirmation && (
          <ConfirmationPopupComponent
            title={lang.EXIT_TITLE}
            message={lang.EXIT_MESSAGE}
            onConfirm={dismiss}
            onDismiss={closeConfirmationPopup}
            confirmMessage={lang.EXIT_CONFIRM}
            dismissMessage={lang.EXIT_DISMISS}
            confirmButtonStyle="primary"
            dismissButtonStyle="secondary"
          />
        )}
      </KnowledgeViewLayout>
    </form>
  );
});

KnowledgeContentEditorComponent.defaultProps = {
  lang: Lang.KNOWLEDGE_EDITOR,
  knowledge: {},
  categoryId: null,
  createKnowledgeItem: () => {},
  updateKnowledgeItem: () => {},
  uploadFile: () => {},
  onFinish: () => {},
};

export { KnowledgeContentEditorComponent };
