import { FEATURES } from '../../constants/features.constants';

export const isFeatureEnabled = ({ organization, auth }, feature = '') => {
  try {
    const { features: userFeatures = [] } = auth?.user || {};
    const { features: organizationFeatures = [] } = organization || {};

    const features = new Set([...userFeatures, ...organizationFeatures]);

    return features.has(feature);
  } catch (e) {
    console.error(e.message);
    return false;
  }
};

export const isContactsFeatureEnabled = ({ organization = {}, auth = {} }) =>
  isFeatureEnabled({ organization, auth }, FEATURES.CONTACTS);

export const isSuggestContentFeatureEnabled = ({ organization = {}, auth = {} }) =>
  isFeatureEnabled({ organization, auth }, FEATURES.SUGGEST_CONTENT);

export const isImprovedSearchEnabled = ({ organization = {}, auth = {} }) =>
  isFeatureEnabled({ organization, auth }, FEATURES.IMPROVED_SEARCH);

export const isTermsAndConditionsEnabled = ({ organization = {}, auth = {} }) =>
  isFeatureEnabled({ organization, auth }, FEATURES.TERMS_AND_CONDITIONS);

export const isSuggestKeywordsEnabled = ({ organization = {}, auth = {} }) =>
  isFeatureEnabled({ organization, auth }, FEATURES.SUGGEST_KEYWORDS);

export const isRotationsEnabled = ({ organization = {}, auth = {} }) =>
  isFeatureEnabled({ organization, auth }, FEATURES.ROTATIONS);

export const isAIEnabled = ({ organization = {}, auth = {} }) =>
  isFeatureEnabled({ organization, auth }, FEATURES.AI_ASK_PANDA);

export const isApprovalMarkEnabled = ({ organization = {}, auth = {} }) =>
  isFeatureEnabled({ organization, auth }, FEATURES.APPROVAL_MARK);

export const isKnowledgeHubSharedEnabled = ({ organization = {}, auth = {} }) =>
  isFeatureEnabled({ organization, auth }, FEATURES.KNOWLEDGE_HUB_SHARED);

export const isOwnerPermissionsRestrictionEnabled = ({ organization = {}, auth = {} }) =>
  isFeatureEnabled({ organization, auth }, FEATURES.PERMISSIONS_OWNER_RESTRICTION);

export const isKnowledgeCommentsEnabled = ({ organization = {}, auth = {} }) =>
  isFeatureEnabled({ organization, auth }, FEATURES.KNOWLEDGE_COMMENTS);
