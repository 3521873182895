import { useEffect, useMemo, useState } from 'react';
import {
  TAGS_SPECIALTY,
  TAGS_ROTATION,
  PROFESSIONS,
  ROLES_DOCTOR,
  ROLES_NURSE,
  ROLES_PARAMEDIC,
  ROLES_LOGISTICS,
  ROLES_CRNA,
  ROLES_CAA,
  ROLES_ALL,
  TRAINING_LEVELS_MEDICAL_STUDENT,
  TRAINING_LEVELS_RESIDENT,
  LANGUAGES,
  TRAINING_LEVELS_ALL,
  USER_TAGS_KEYS,
} from '../constants';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import rotationsServiceApi from '../api/rotations.service.api';

const PROFESSIONS_WITH_ROLES = [
  PROFESSIONS.MEDICAL_DOCTOR,
  PROFESSIONS.NURSE,
  PROFESSIONS.PARAMEDIC,
  PROFESSIONS.LOGISTICS_OPERATIONS,
];
const ROLES_WITH_LEVEL_OF_TRAINING = [ROLES_ALL.MEDICAL_STUDENT, ROLES_ALL.RESIDENT];

const useProfileTagsOption = options => {
  const LANG_TAGS = useSelector(getLang('TAGS'));
  const LANG_PROFILE = useSelector(getLang('PROFILE_OPTIONS'));
  const [rotations, setRotations] = useState(
    Object.values({ ...TAGS_SPECIALTY, ...TAGS_ROTATION }),
  );

  const { stats = {} } = options;

  const createOptions = (values, labels, key, stats) => {
    let valuesToParse = values;

    if (!Array.isArray(values)) {
      valuesToParse = Object.values(values);
    }

    return valuesToParse
      .filter(el => {
        const keyStats = stats?.[key] || {};
        return keyStats[el] || Object.keys(keyStats).length === 0;
      })
      .map(el => {
        const keyStats = stats?.[key] || {};
        const value = `${key}.${el}`;
        const label = `${labels?.[el] ?? el}${keyStats[el] ? ` (${keyStats[el]})` : ''}`;
        return { value, label };
      });
  };

  const useOptions = (values, labels, key, lang, stats) =>
    useMemo(
      () => createOptions(values, labels, key, stats),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [lang, stats, rotations],
    );

  useEffect(() => {
    async function fetchRotations() {
      const rotations = await getRotationsOptions();

      setRotations(prevRotations => [...prevRotations, ...rotations]);
    }
    fetchRotations();
  }, []);

  const departmentOptions = useOptions(
    TAGS_SPECIALTY,
    LANG_TAGS,
    USER_TAGS_KEYS.DEPARTMENT,
    LANG_TAGS,
    stats,
  );

  const rotationOptions = useOptions(
    rotations,
    LANG_TAGS,
    USER_TAGS_KEYS.ROTATION,
    LANG_TAGS,
    stats,
  );

  const professionOptions = useOptions(
    PROFESSIONS,
    LANG_PROFILE,
    USER_TAGS_KEYS.PROFESSION,
    LANG_PROFILE,
    stats,
  );

  const roleOptions = useOptions(ROLES_ALL, LANG_PROFILE, USER_TAGS_KEYS.ROLE, LANG_PROFILE, stats);

  const levelOfTrainingOptions = useOptions(
    TRAINING_LEVELS_ALL,
    LANG_PROFILE,
    USER_TAGS_KEYS.LEVEL_OF_TRAINING,
    LANG_PROFILE,
    stats,
  );

  return {
    departmentOptions,
    rotationOptions,
    professionOptions,
    roleOptions,
    levelOfTrainingOptions,
  };
};

const useProfileOptions = ({ profession, role, department }) => {
  const LANG_TAGS = useSelector(getLang('TAGS'));
  const LANG_PROFILE = useSelector(getLang('PROFILE_OPTIONS'));
  const [rotations, setRotations] = useState([]);

  const createOptions = (values, labels, filterValue = null) => {
    let valuesToParse = values;

    if (!Array.isArray(values)) {
      valuesToParse = Object.values(values);
    }

    return valuesToParse
      .filter(el => (!filterValue ? el : el !== filterValue))
      .map(el => ({
        id: el,
        value: labels?.[el] ?? el,
      }));
  };

  const departmentOptions = useMemo(() => createOptions(TAGS_SPECIALTY, LANG_TAGS), [LANG_TAGS]);

  useEffect(() => {
    async function fetchRotations() {
      const rotations = await getRotationsOptions();
      setRotations(rotations);
    }
    fetchRotations();
  }, [department, LANG_TAGS]);

  const rotationOptions = useMemo(
    () => createOptions(rotations, LANG_TAGS, department),
    [rotations, department, LANG_TAGS],
  );

  const professionOptions = useMemo(() => createOptions(PROFESSIONS, LANG_PROFILE), [LANG_PROFILE]);

  const roleOptions = useMemo(() => {
    let roles = null;

    if (profession === PROFESSIONS.MEDICAL_DOCTOR) {
      roles = ROLES_DOCTOR;
    }
    if (profession === PROFESSIONS.NURSE) {
      roles = ROLES_NURSE;
    }
    if (profession === PROFESSIONS.PARAMEDIC) {
      roles = ROLES_PARAMEDIC;
    }
    if (profession === PROFESSIONS.LOGISTICS_OPERATIONS) {
      roles = ROLES_LOGISTICS;
    }
    if (profession === PROFESSIONS.CRNA) {
      roles = ROLES_CRNA;
    }
    if (profession === PROFESSIONS.CAA) {
      roles = ROLES_CAA;
    }

    if (!roles) return null;

    return createOptions(roles, LANG_PROFILE);
  }, [LANG_PROFILE, profession]);

  const levelOftrainingOptions = useMemo(() => {
    let levelOfTraining = null;

    if (role === ROLES_ALL.MEDICAL_STUDENT) {
      levelOfTraining = TRAINING_LEVELS_MEDICAL_STUDENT;
    }
    if (role === ROLES_ALL.RESIDENT) {
      levelOfTraining = TRAINING_LEVELS_RESIDENT;
    }

    if (!levelOfTraining) return null;

    return createOptions(levelOfTraining, LANG_PROFILE);
  }, [LANG_PROFILE, role]);

  const timezoneOptions = createOptions(moment.tz.names());
  const languageOptions = useMemo(() => createOptions(LANGUAGES, LANG_PROFILE), [LANG_PROFILE]);

  return {
    departmentOptions,
    rotationOptions,
    professionOptions,
    roleOptions,
    levelOftrainingOptions,
    timezoneOptions,
    languageOptions,
  };
};

const getRotationsOptions = async () => {
  const { data } = await rotationsServiceApi.getRotationsList();

  if (!data?.length) return Object.values({ ...TAGS_SPECIALTY, ...TAGS_ROTATION });

  return data.map(rotation => rotation.name);
};

export {
  useProfileOptions,
  useProfileTagsOption,
  PROFESSIONS_WITH_ROLES,
  ROLES_WITH_LEVEL_OF_TRAINING,
};
