import React from 'react';
import { FAVORITES_ENTITIES, ROUTES, INTERNAL_ROUTING, CATEGORY_TYPES } from 'shared/constants';
import { ShareBoxComponent } from '../../../../components/share';
import { FavoritesButton } from '../../../../components/favorites-button';
import styles from '../contacts.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';

const ContactRow = ({ index, style, list, openDetails }) => {
  const departmentsLang = useSelector(getLang('TAGS'));
  const contact = list[index];

  return (
    <div style={style}>
      <div
        className={styles.contactRow}
        key={`contact-row-${contact.id}`}
        onClick={() => openDetails(contact.id)}
      >
        <div className={styles.nameWrapper}>
          <div className={styles.name}>
            {contact?.firstName} {contact?.lastName ? contact?.lastName : ''}
          </div>
          <div className={styles.department}>
            {departmentsLang[contact?.professional?.department] || ''}
          </div>
        </div>
        <div className={styles.phone}>{contact?.phone}</div>
        <div className={styles.pager}>{contact?.pager}</div>
        <div className={styles.pager}>{contact?.cisco}</div>
        <div className={styles.email}>
          {contact?.email && <a href={`mailto:${contact?.email}`}>{contact?.email}</a>}
        </div>
        <div className={styles.actionIcons}>
          <button type="button" className={styles.buttonWrapper}>
            <ShareBoxComponent
              path={`${ROUTES.CONTACTS}?${INTERNAL_ROUTING.QUERY_PARAMS.CONTACTS}=${contact.id}`}
              id={contact.id}
              title={contact?.firstName}
              entityType={CATEGORY_TYPES.KNOWLEDGE}
              iconClassName={styles.actionIcon}
            />
          </button>
          <FavoritesButton
            id={contact.id}
            entity={contact.isUser ? FAVORITES_ENTITIES.USER : FAVORITES_ENTITIES.CONTACT}
            background={styles.transparentBackground}
            wrapper={styles.buttonWrapper}
            className={styles.actionIcon}
          />
        </div>
      </div>
    </div>
  );
};

export { ContactRow };
