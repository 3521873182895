import React from 'react';
import { normalizedContains } from 'shared/utils';
import styles from './forms.module.scss';
import { ConfigProvider, Select } from 'antd';
import { antdTheme as theme } from 'shared/constants';
import _ from 'lodash';

const { Option } = Select;

const SelectBox = React.memo(function SelectBox({
  label = null,
  value,
  options,
  onChange,
  name = '',
  placeholder = '',
  error = null,
  disabled = false,
  multiple = false,
  optionsRender = null,
  allowClear = false,
  ...props
}) {
  const handleOnChange = value => {
    onChange(name, value);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            borderRadius: 8,
            controlHeight: 48,
            colorBorder: theme.colorBorder,
            colorTextPlaceholder: theme.colorTextPlaceholder,
          },
        },
      }}
    >
      <div className={styles.root}>
        {label && <label>{label}</label>}
        <Select
          mode={multiple && 'multiple'}
          value={!_.isEmpty(value) && !disabled ? value : null}
          error={error}
          status={error && 'error'}
          placeholder={placeholder}
          name={name}
          onChange={handleOnChange}
          filterOption={(inputValue, option) => normalizedContains(option?.value, inputValue)}
          disabled={disabled}
          showSearch
          allowClear={allowClear}
          {...props}
        >
          {optionsRender
            ? optionsRender(options)
            : options.map(({ id, value }) => (
                <Option value={id} key={id}>
                  {value}
                </Option>
              ))}
        </Select>
        <span className={styles.error}>{error}</span>
      </div>
    </ConfigProvider>
  );
});

export { SelectBox };
