import { FEATURES } from '../../constants/features.constants';
import { isAccountAdmin } from './auth.selector';
import {
  isKnowledgeHubSharedEnabled,
  isOwnerPermissionsRestrictionEnabled,
} from './features.selector';

/**
 * Check if the user have permissions and check feature restrictions
 * @param {ReduxState} { auth, organization }
 * @return {Boolean} if the user have permissions to edit the item
 */
export function hasPermissionsToEditItem({ auth, organization }) {
  const isAdmin = isAccountAdmin({ auth });
  const isFeatureEnabled = isOwnerPermissionsRestrictionEnabled(
    { auth, organization },
    FEATURES.PERMISSIONS_OWNER_RESTRICTION,
  );

  return isAdmin || !isFeatureEnabled;
}

/**
 * Check if the user have permissions to access knowledge hub
 * @param {ReduxState} { auth, organization }
 * @return {Boolean} if the user have permissions to see knowledge hub
 */
export function hasPermissionsToAccessKnowledgeHub({ auth, organization }) {
  const isAdmin = isAccountAdmin({ auth });
  const isFeatureEnabled = isKnowledgeHubSharedEnabled({ auth, organization });

  return isAdmin && isFeatureEnabled;
}
