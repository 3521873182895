import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateKnowledgeItem } from 'shared/store/actions/knowledge.items.actions';
import { getLang } from 'shared/store/selectors/lang.selector';

export const useUpdateKnowledgeItem = ({ item, openNotification }) => {
  const lang = useSelector(getLang('KNOWLEDGE_EDITOR'));
  const [itemData] = useState(item);
  const dispatch = useDispatch();

  const submitUpdate = useCallback(
    async itemData => {
      try {
        await dispatch(updateKnowledgeItem(itemData));

        openNotification({ message: lang.SHARE_SUCCESS_MESSAGE });
      } catch (error) {
        openNotification({
          message: lang.SHARE_ERROR_MESSAGE,
          type: 'error',
        });
      }
    },
    [dispatch, openNotification, lang],
  );

  const setFieldValue = useCallback(
    (name, value) => {
      submitUpdate({ ...itemData, [name]: value });
    },
    [itemData, submitUpdate],
  );

  return { setFieldValue };
};
