import React, { useState } from 'react';
import styles from '../contacts.module.scss';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { FixedSizeList } from 'react-window';
import { ContactRow } from './contact-row.component';
import { ITEM_SIZE } from '../contacts.component';

const ContactGroup = ({ list, label, openDetails, expanded = false }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  const renderExpandIcon = ({ isActive }) => (
    <div className={classnames([styles.arrowContainer, isActive && styles.active])}>
      <FontAwesomeIcon
        icon={faAngleRight}
        className={classnames([styles.arrow, isActive && styles.active])}
      />
    </div>
  );

  return (
    <>
      <div
        className={classnames([styles.contactRow, styles.groupRow])}
        onClick={() => setIsExpanded(state => !state)}
      >
        {renderExpandIcon({ isActive: isExpanded })}
        <div className={classnames([styles.groupHeader])}>{`${label} (${list.length})`}</div>
      </div>
      {isExpanded && (
        <FixedSizeList
          height={list.length * ITEM_SIZE}
          itemCount={list.length}
          itemSize={ITEM_SIZE}
          width={'100%'}
        >
          {({ index, style }) => {
            return <ContactRow {...{ index, style, list, openDetails }} />;
          }}
        </FixedSizeList>
      )}
    </>
  );
};

export { ContactGroup };
