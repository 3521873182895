import React from 'react';
import styles from './forms.module.scss';
import { ConfigProvider, Input } from 'antd';
import { antdTheme as theme } from 'shared/constants';

/**
 * @typedef {import('antd').InputProps & {
 *     label: string,
 *    error: string,
 *   size: 'small' | 'large',
 * }} TextInputProps
 */

const TextInput = React.memo(
  /**
   * @param {TextInputProps} param0
   * @returns
   */
  function TextInput({ label = null, error = null, size = 'large', ...props }) {
    return (
      <ConfigProvider
        theme={{
          components: {
            Input: {
              borderRadius: 8,
              colorBorder: theme.colorBorder,
              colorTextPlaceholder: theme.colorTextPlaceholder,
            },
          },
        }}
      >
        <div className={styles.root}>
          {label && <label>{label}</label>}
          <Input status={error && 'error'} size={size} {...props} />
          <span className={styles.error}>{error}</span>
        </div>
      </ConfigProvider>
    );
  },
);

export { TextInput };
