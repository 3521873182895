import { connect } from 'react-redux';
import {
  fetchUsers,
  setPermission,
  deleteUser,
} from 'shared/store/actions/users-management.actions';
import { UserManagementPageComponent } from './users-management.component';

const mapStateToProps = ({ lang, usersManagement }) => {
  return {
    lang: lang.USERS_MANAGEMENT,
    users: usersManagement.list,
  };
};

export const UserManagementPage = connect(mapStateToProps, {
  fetchUsers,
  setPermission,
  deleteUser,
})(UserManagementPageComponent);
