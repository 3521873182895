import React from 'react';
import { SelectInput } from '../core';
import styles from './drugClassSelector.module.scss';
import { normalizedContains } from '../../utils';
import { Select } from 'antd';
import { DrugClassIcon } from './drugClassIcon/DrugClassIcon.component';

const { Option } = Select;

const DrugClassSelectorComponent = React.memo(function DrugClassSelectorComponent({
  value,
  options,
  onChange,
  name = 'drugClass',
  placeholder,
  error = null,
}) {
  return (
    <SelectInput
      value={value ? value : null}
      options={options}
      error={error}
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      inputStyle={{ fontSize: '17px' }}
      wrapperClass={styles.selector}
      allowClear
      optionsRender={options =>
        options.map(({ id, value }) => (
          <Option value={id} key={id}>
            <div className={styles.option}>
              {value}
              <DrugClassIcon drugClass={id} tooltip={false} />
            </div>
          </Option>
        ))
      }
      filterOption={(inputValue, option) =>
        normalizedContains(option?.children?.props?.children[0], inputValue)
      }
    />
  );
});

export { DrugClassSelectorComponent };
