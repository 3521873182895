import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Email').required('Required'),
});

export const notAllowedSSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Email').required('Required'),
  fullName: Yup.string().required('Required'),
  phone: Yup.string(),
});
