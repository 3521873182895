import React from 'react';
import { DoctorSpirit, GooglePlay, AppStore } from '../../images';
import styles from './MobilePopover.module.scss';
import { Lang } from 'shared/constants';

const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
const appStoreLink = 'https://apple.co/34Bf5jL';
const playStoreLink = 'https://play.google.com/store/apps/details?id=com.headtotoe.app';

function MobilePopover({ children, lang }) {
  if (!isMobile) {
    return children;
  }

  return (
    <div className={styles.root}>
      <img src={DoctorSpirit} className={styles.doctor} alt={lang.HEADER} />
      <h1 className={styles.header}>{lang.HEADER}</h1>
      <h3 className={styles.subHeader}>{lang.SUBHEADER}</h3>

      <div className={styles.download}>
        <a href={appStoreLink}>
          <img src={AppStore} className={styles.downloadButton} alt="App Store" />
        </a>
        <a href={playStoreLink}>
          <img src={GooglePlay} className={styles.downloadButton} alt="Play Store" />
        </a>
      </div>
    </div>
  );
}

MobilePopover.defaultProps = {
  lang: Lang.MOBILE_POPOVER,
};

export { MobilePopover };
