import React, { useCallback, useState } from 'react';
import { Popup } from '../common';
import { Button, Input } from '../core';
import styles from './knowledge-upload-file/knowledge-upload-file.module.scss';
import { useSelector } from 'react-redux';
import { KNOWLEDGE_TYPES } from 'shared/constants/knowledge.constants';
import { getLang } from 'shared/store/selectors/lang.selector';

const KnowledgeUploadExternalLinkComponent = React.memo(
  function KnowledgeUploadExternalLinkComponent({ close, editExternal, ...props }) {
    const lang = useSelector(getLang('KNOWLEDGE_UPLOAD_POPUP'));
    const langEditor = useSelector(getLang('KNOWLEDGE_EDITOR'));
    const [link, setLink] = useState('');

    const onUploadLink = useCallback(
      e => {
        setLink(e.target.value);
      },
      [setLink],
    );
    const onSubmit = useCallback(async () => {
      editExternal({ ...props, link: link, type: KNOWLEDGE_TYPES.EXTERNAL_LINK });
      close();
    }, [close, props, editExternal, link]);

    return (
      <Popup isOpen close={close}>
        <h4 className={styles.title}>{lang.TITLE}</h4>

        <label>{langEditor.LINK}</label>
        <Input
          inputStyle="border"
          name="link"
          value={link}
          onChange={onUploadLink}
          placeholder={lang.LINK_PLACEHOLDER}
          small
        />

        <div className={styles.buttons}>
          <Button className={styles.button} size={'medium'} onClick={close}>
            {lang.DISMISS}
          </Button>
          <Button
            className={styles.button}
            size={'medium'}
            buttonStyle="primary"
            onClick={onSubmit}
            disabled={!link}
          >
            {lang.SUBMIT}
          </Button>
        </div>
      </Popup>
    );
  },
);

KnowledgeUploadExternalLinkComponent.defaultProps = {
  close: () => {},
  editContent: () => {},
  editExternal: () => {},
};

export { KnowledgeUploadExternalLinkComponent };
