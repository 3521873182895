import React from 'react';
import styles from './SelectInput.module.scss';
import classnames from 'classnames';
import { Select } from 'antd';
import { Tag } from '../tag';

const { Option } = Select;

const SelectInput = React.memo(
  ({
    name,
    error,
    touched,
    wrapperClass,
    inputStyle,
    onChange,
    value,
    options,
    disabled,
    optionsRender,
    ...props
  }) => {
    const onChangeLocal = value => {
      onChange(name, value);
    };

    return (
      <div className={styles.root}>
        <div className={classnames(styles.wrapper, wrapperClass)}>
          <Select
            showSearch
            bordered={false}
            defaultValue={value}
            value={!disabled ? value : null}
            className={classnames(styles.input, inputStyle)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={onChangeLocal}
            disabled={disabled}
            tagRender={Tag}
            size="small"
            {...props}
          >
            {optionsRender
              ? optionsRender(options)
              : options.map(({ id, value }) => (
                  <Option value={id} key={id}>
                    {value}
                  </Option>
                ))}
          </Select>
        </div>
        {error && <span className={styles.error}>{error}</span>}
      </div>
    );
  },
);

SelectInput.defaultProps = {
  error: null,
  onChange: () => {},
  optionsRender: null,
  name: null,
  wrapperClass: null,
  inputStyle: {},
  options: [],
};

export { SelectInput };
