import React, { useCallback, useMemo } from 'react';
import styles from './oauth-confirmation-modal.module.scss';
import { useOauthConfig } from '../../authentication/hooks/use-oauth-config.hook';
import { Button } from 'src/components/design-system';

const OAuthConfirm = ({ organization, lang, open, close }) => {
  const { getConfigByProvider, generateAuthenticationUrl } = useOauthConfig();
  const provider = useMemo(
    () => organization?.auth.oauth.redirectUri.split('/').pop(),
    [organization],
  );
  const config = useMemo(() => getConfigByProvider(provider), [provider, getConfigByProvider]);

  const loginWithOauth = useCallback(() => {
    if (!organization || organization.auth.type !== 'OAuth') {
      return;
    }
    window.location.href = generateAuthenticationUrl(config);
  }, [organization, config, generateAuthenticationUrl]);

  return (
    <div className={styles.container}>
      <img src={config.logo} alt="logo" className={styles.logo} />
      <Button onClick={loginWithOauth} className={styles.button} type="primary">
        {lang.SIGN_IN.replaceAll('{organization}', organization?.name)}
      </Button>
      <div className={styles.footer}>
        {lang.REDIRECT_MESSAGE.replaceAll('{organization}', organization?.name)
          .split('.')
          .map(part => (
            <p>{part}.</p>
          ))}
      </div>
    </div>
  );
};

export { OAuthConfirm };
