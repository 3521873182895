import React from 'react';
import { Lang, DATA_KEYS } from 'shared/constants';
import { Breadcrumbs } from '../../../components/breadcrumbs';
import { KnowledgeSectionList, KnowledgeSection, RenderSectionList } from './components';
import styles from './knowledge.module.scss';
import { ScreenTitle } from 'src/components/design-system';
import { ViewSelector } from 'src/components/common/view-selector';
import classnames from 'classnames';
const { KNOWLEDGE_BASE } = DATA_KEYS;

const KnowledgePageComponent = React.memo(
  ({
    id,
    title,
    children,
    knowledgeItems,
    updatedAt,
    lastUpdated,
    fullPath,
    pathParts,
    ownerIds,
    tags,
    shared,
    isRoot = false,
    isReady = false,
  }) => {
    const renderContent = () => {
      if (knowledgeItems.length) {
        // TODO: Add redirect to parent category with opened knowledge items list
        return (
          <KnowledgeSection
            {...{ title, children, knowledgeItems, updatedAt, fullPath, ownerIds, tags, shared }}
          />
        );
      }

      return (
        <KnowledgeSectionList
          isReady={isReady}
          list={children}
          ownerIds={ownerIds}
          key={lastUpdated}
          fullPath={fullPath}
          id={id}
          tags={tags}
          shared={shared}
        />
      );
    };

    return (
      <div
        className={classnames([styles.root, 'new-design-system'])}
        data-key={KNOWLEDGE_BASE.LIST}
      >
        <aside>
          <RenderSectionList sections={children} />
        </aside>
        <main>
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              {!isRoot && <Breadcrumbs pathParts={pathParts} title={title} />}
              <ScreenTitle title={title} />
            </div>
            <ViewSelector />
          </div>
          {renderContent()}
        </main>
      </div>
    );
  },
);

KnowledgePageComponent.defaultProps = {
  lang: Lang.HOME_SCREEN,
  list: null,
  isReady: false,
  lastUpdated: Date.now(),
  children: null,
  knowledgeItems: [],
  fullPath: '',
  pathParts: [],
  ownerIds: [],
};

export { KnowledgePageComponent };
