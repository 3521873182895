import { useSelector } from 'react-redux';
import { isApprovalMarkEnabled } from 'shared/store/selectors/features.selector';
import { getLang } from 'shared/store/selectors/lang.selector';
import styles from './approve-banner.module.scss';
import { ORGANIZATIONS_LOGO_URL } from 'shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';

export function ApproveBanner() {
  const [isBannerVisible, setBannerVisibility] = useState(true);
  const lang = useSelector(getLang('KNOWLEDGE_BANNER'));
  const isApprovalMark = useSelector(isApprovalMarkEnabled);
  const { name, logo } = useSelector(state => state.organization);

  if (!isApprovalMark) {
    return null;
  }

  const hideBanner = () => {
    setBannerVisibility(false);
  };

  return isBannerVisible ? (
    <div className={styles.root}>
      <div className={styles.textWrapper}>
        <p className={styles.title}>{lang.BANNER_TITLE.replace('{ORG_NAME}', name)}</p>
        <p>{lang.BANNER_SUBTITLE.replace('{ORG_NAME}', name)}</p>
      </div>
      <div className={styles.logoWrapper}>
        <img src={`${ORGANIZATIONS_LOGO_URL}${logo}`} className={styles.orgLogo} alt="org logo" />
      </div>
      <FontAwesomeIcon
        color="#767676"
        className={styles.closeIcon}
        icon={faCircleXmark}
        size="lg"
        onClick={hideBanner}
      />
    </div>
  ) : (
    <></>
  );
}
