import { connect } from 'react-redux';
import {
  knowledgeSelectItem,
  knowledgeSetItemDetails,
} from 'src/store/actions/knowledgeViewer.actions';
import { KnowledgeInfoComponent } from './knowledge-info.component';
import {
  getItemById,
  getCategoryById,
  getKnowledgeParentsByItemId,
} from 'shared/store/selectors/knowledge.selector';
import { getOwnerById, getOwnersByIDs } from 'shared/store/selectors/owners.selector';

const mapStateToProps = ({ knowledgeViewer, knowledge, owners, lang }) => {
  let { itemId, ownerId, categoryId } = knowledgeViewer;

  if (!itemId) {
    return {
      itemId,
      lang: lang.INFORMATION_MODAL,
    };
  }

  let knowledgeItem = itemId ? getItemById(knowledge, { id: itemId }) : null;

  let ownerObject = ownerId ? getOwnerById(owners, { id: ownerId }) : null;

  const { ownerIds } = knowledgeItem || {};
  const ownersList = getOwnersByIDs(ownerIds)({ owners });

  let parents = !!categoryId
    ? [getCategoryById(knowledge, { id: categoryId })]
    : getKnowledgeParentsByItemId(knowledge, { id: itemId });

  return {
    itemId,
    ...knowledgeItem,
    ownerObject,
    owners: ownersList,
    parents,
    lang: lang.INFORMATION_MODAL,
  };
};

export const KnowledgeInfo = connect(mapStateToProps, {
  knowledgeSetItemDetails,
  knowledgeSelectItem,
})(KnowledgeInfoComponent);
