import React from 'react';
import { Lang, CATEGORY_TYPES, EVENTS, ANALYTICS_CONTENT_CATEGORIES_TYPES } from 'shared/constants';
import { Popup } from 'src/components/common';
import { Button, Input, Loader, SelectInput } from 'src/components/core';
import styles from './knowledge-category-editor-popup.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { normalizedContains } from 'src/utils';
import { TagsSelector, TAG_SELECTOR_TYPE } from 'src/components/tags/tagSelector';
import { HorizontalDirectoryKnowledgeIcon, HorizontalBinderIcon } from 'src/images';
import { Switch } from 'antd';
import { LabelsSelector } from 'src/components/labels/labels-selector';
import { ShareToHub } from 'src/components/share-to-hub';
import { useSelector } from 'react-redux';
import { isParentSharedToHub, getCategoryById } from 'shared/store/selectors/knowledge.selector';
import analyticsService from 'src/helpers/analytics.service';
import { getCategoryType } from 'shared/utils';
import { MultipleOwnersSelector } from 'src/components/owners/owners-selector/multiple-owners-selector';
import { PopupFormButtons } from 'src/components/common/popup-form-buttons';

const getKnowledgeCategoryTypes = lang => ({
  [CATEGORY_TYPES.CATEGORY]: {
    label: lang?.CATEGORY_TYPE?.[CATEGORY_TYPES.CATEGORY],
  },
  [CATEGORY_TYPES.KNOWLEDGE]: {
    label: lang?.CATEGORY_TYPE?.[CATEGORY_TYPES.KNOWLEDGE],
  },
});

const KnowledgeCategoryEditorPopupComponent = React.memo(
  function KnowledgeCategoryEditorPopupComponent({
    lang,
    updateCategory,
    createCategory,
    onFinish,
    entity,
    locations,
  }) {
    let isNew = !entity.id;
    let { path, isSectionCreation } = entity;
    let isCategoryCreation = !isNew || !path || isSectionCreation;
    const isSection = isSectionCreation || path.split(',').length % 2 !== 0;

    const [isLoading, setLoadingState] = React.useState(false);
    const [type, setType] = React.useState(
      isCategoryCreation ? CATEGORY_TYPES.SECTION : CATEGORY_TYPES.KNOWLEDGE,
    );

    const isParentShared = useSelector(isParentSharedToHub(entity.path)); // in case of section creation
    const category = useSelector(({ knowledge }) => getCategoryById(knowledge, { id: entity.id }));

    const KNOWLEDGE_CATEGORY_SCHEMA = React.useMemo(
      () =>
        Yup.object().shape({
          title: Yup.string().min(2, 'Too Short!').max(200, 'Too Long!').required('Required!'),
          subtitle: Yup.string().max(150, 'Too Long!'),
          type: Yup.string().required(),
          ownerIds: Yup.array()
            .of(Yup.string())
            .min(1, 'Please assign at least one owner')
            .required('Required!'),
          specialty: Yup.string().nullable(),
          subspecialty: Yup.string().nullable(),
          contentType: Yup.string().nullable(),
          targetAudience: Yup.array().of(Yup.string()),
          hideInWidget: Yup.boolean(),
          // TODO: add the labels as an input
          labels: Yup.array().of(Yup.string()),
          shareToHub: Yup.boolean(),
        }),
      [],
    );

    const updateOrGenerateKnowledge = React.useCallback(
      async values => {
        setLoadingState(true);
        // TODO: create the category
        let categoryRes;
        const {
          specialty,
          subspecialty,
          contentType,
          targetAudience,
          hideInWidget,
          ...valuesData
        } = values;

        const data = {
          ...valuesData,
          tags: { specialty, subspecialty, contentType, targetAudience },
          options: { hideInWidget },
        };

        if (data.shareToHub === undefined) {
          delete data.shareToHub;
        }

        if (isNew) {
          if (isParentShared) {
            data.isParentShared = true;
          }
          categoryRes = await createCategory(data);

          if (categoryRes) {
            analyticsService.track(EVENTS.CATEGORIES.ADD, {
              categoryType: getCategoryType({
                path: categoryRes.path,
                type: categoryRes.type,
                isSectionCreation,
                isNew: true,
              }),
              contentType: ANALYTICS_CONTENT_CATEGORIES_TYPES.KNOWLEDGE,
              categoryId: categoryRes.id,
              title: categoryRes.title,
              ownerIds: categoryRes.ownerIds,
            });
          }
        } else {
          categoryRes = await updateCategory(data);

          analyticsService.track(EVENTS.CATEGORIES.EDIT, {
            categoryType: getCategoryType({ path: values.path, type: values.type }),
            contentType: ANALYTICS_CONTENT_CATEGORIES_TYPES.KNOWLEDGE,
            categoryId: values.id,
            title: values.title,
            ownerIds: values.ownerIds,
          });
        }

        setLoadingState(false);
        onFinish({ ...categoryRes, isSectionCreation });
      },
      [createCategory, isNew, isSectionCreation, onFinish, updateCategory, isParentShared],
    );

    const dismiss = React.useCallback(() => {
      // TODO: analytics

      onFinish({});
    }, [onFinish]);

    const { handleChange, handleSubmit, setFieldValue, resetForm, values, errors, isValid, dirty } =
      useFormik({
        validationSchema: KNOWLEDGE_CATEGORY_SCHEMA,
        initialValues: {
          type: isCategoryCreation ? CATEGORY_TYPES.CATEGORY : CATEGORY_TYPES.KNOWLEDGE,
          ...entity.tags,
          ...entity,
          ...entity?.options,
          shareToHub: category?.shared?.isShared || (isParentShared && isNew ? true : undefined),
        },
        onSubmit: updateOrGenerateKnowledge,
      });

    const selectCategoryType = React.useCallback(
      e => {
        e.preventDefault();
        let { value } = e.target;

        setFieldValue('type', value);
        setType(value);
      },
      [setFieldValue],
    );

    const resetField = React.useCallback(
      field => {
        const newValues = { values };
        delete newValues.values[field];
        resetForm(newValues);
      },
      [values, resetForm],
    );

    const onKeyDown = React.useCallback(e => e.key === 'Enter' && e.preventDefault(), []);

    const onShowOnWidgetToggle = React.useCallback(
      checked => {
        setFieldValue('hideInWidget', !checked);
      },
      [setFieldValue],
    );

    const renderIcon = key => {
      if (key === CATEGORY_TYPES.CATEGORY) {
        return <img src={HorizontalDirectoryKnowledgeIcon} className={styles.icon} alt={key} />;
      }

      return <img src={HorizontalBinderIcon} alt={key} className={styles.icon} />;
    };

    const renderCategoryTypes = () => {
      if (isCategoryCreation) return null;

      return (
        <>
          <p data-key="content-type-label">{lang.SELECT_CATEGORY_TYPE}</p>
          <div className={styles.categoriesTypes}>
            {Object.keys(getKnowledgeCategoryTypes(lang)).map(key => (
              <Button
                key={key}
                value={key}
                className={styles.categorySelect}
                onClick={selectCategoryType}
                buttonStyle={key === values.type && 'secondary'}
                size="medium"
              >
                {renderIcon(key)}
                {getKnowledgeCategoryTypes(lang)[key].label}
              </Button>
            ))}
          </div>
        </>
      );
    };

    const isKnowledgeType = values.type === CATEGORY_TYPES.KNOWLEDGE;
    let title = isNew ? lang.CREATE_TITLE : lang.UPDATE_TITLE;

    if (type === CATEGORY_TYPES.SECTION) {
      title = isNew ? lang.CREATE_SECTION_TITLE : lang.UPDATE_SECTION_TITLE;
    }

    return (
      <Popup title={title} isOpen>
        <form onSubmit={handleSubmit} data-key="content-editor">
          {renderCategoryTypes()}
          <>
            <label data-key="content-name-label">{lang.INSERT_CATEGORY_NAME[type]}</label>
            <Input
              name="title"
              value={values.title}
              onChange={handleChange}
              onKeyDown={onKeyDown}
              inputStyle="editor"
              placeholder={lang.INSERT_CATEGORY_NAME_PLACEHOLDER[type]}
            />
          </>
          {isKnowledgeType && (
            <>
              <label data-key="content-subtitle-label">{lang.DESCRIPTION}</label>
              <Input
                name="subtitle"
                value={values.subtitle}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                inputStyle="editor"
                placeholder={lang.DESCRIPTION_PLACEHOLDER}
              />
            </>
          )}

          <label data-key="content-owner-label">{lang.OWNER}</label>
          <MultipleOwnersSelector
            value={values.ownerIds}
            error={errors.ownerIds}
            placeholder={lang.OWNER_PLACEHOLDER}
            name="ownerIds"
            onChange={setFieldValue}
            bordered
            borderRadius={4}
          />

          <TagsSelector
            selectedValues={values}
            errors={errors}
            onChange={setFieldValue}
            resetField={resetField}
            type={TAG_SELECTOR_TYPE.KNOWLEDGE}
          />

          <LabelsSelector
            value={values.labels}
            error={errors.labels}
            placeholder={lang.LABELS_PLACEHOLDER}
            name="labels"
            onChange={setFieldValue}
            renderLabel={() => <label data-key="content-owner-label">{lang.LABELS}</label>}
          />

          {!isNew && (
            <>
              <label data-key="content-location-label">{lang.LOCATION}</label>
              <SelectInput
                value={values.path}
                error={errors.path}
                placeholder={lang.LOCATION_PLACEHOLDER}
                options={locations}
                name="path"
                onChange={setFieldValue}
                filterOption={(inputValue, option) =>
                  normalizedContains(option?.children, inputValue)
                }
              />
            </>
          )}
          {isSection && (
            <div className={styles.widgetToggle}>
              <Switch
                checked={!values.hideInWidget}
                onChange={onShowOnWidgetToggle}
                className="ant-switch-secondary"
              />
              <label data-key="content-show-on-widget-label">{lang.SHOW_ON_WIDGET}</label>
            </div>
          )}

          <ShareToHub
            name="shareToHub"
            showHelpdesk
            value={values.shareToHub}
            setFieldValue={setFieldValue}
            topBorder
          />

          {!isLoading && (
            <PopupFormButtons
              dismiss={dismiss}
              language={{
                CREATE_OR_UPDATE: isNew ? lang.CREATE : lang.UPDATE,
                DISMISS: lang.DISMISS,
              }}
              disableUpdate={!isValid || !dirty}
            />
          )}
          {isLoading && <Loader />}
          {!isNew && <span className={styles.itemId}>Id: {entity?.id}</span>}
        </form>
      </Popup>
    );
  },
);

KnowledgeCategoryEditorPopupComponent.defaultProps = {
  lang: Lang.CATEGORY_EDITOR,
  createCategory: () => {},
  updateCategory: () => {},
  onFinish: () => {},
  entity: {
    id: null,
    path: null,
    isSectionCreation: false,
  },
  locations: [],
};

export { KnowledgeCategoryEditorPopupComponent };
