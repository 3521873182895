import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ROUTES } from 'shared/constants';
import userService from 'shared/services/user.service';
import { useQuery } from 'src/utils/router.hooks';

export function OauthCallback({ match }) {
  const isAuthenticated = userService.isAuthenticated;
  const query = useQuery();
  const navigator = useNavigate();
  const { provider } = match.params;

  React.useEffect(() => {
    // check if there is code in the query
    if (!query.code) {
      return navigator(ROUTES.WELCOME);
    }

    const redirectRoute = isAuthenticated ? ROUTES.ROOT : ROUTES.WELCOME;

    // if there is code in the query, redirect to the welcome page
    return navigator(redirectRoute, {
      state: { query, provider },
    });
  }, [query, provider, navigator, isAuthenticated]);

  return null;
}
