import { connect } from 'react-redux';
import { knowledgeSelectItem } from '../../../store/actions/knowledgeViewer.actions';
import { updateKnowledgeItemsOrder } from 'shared/store/actions/knowledge.items.actions';
import { KnowledgeBinderComponent } from './knowledge-binder.component';
import { getItemsByCategoryId, getCategoryById } from 'shared/store/selectors/knowledge.selector';
import { INTERNAL_ROUTING } from 'shared/constants';
import { getQueryParam } from '../../../helpers/routing.helper';

const mapStateToProps = ({ knowledge, application, lang }) => {
  const id = getQueryParam({ key: INTERNAL_ROUTING.QUERY_PARAMS.KNOWLEDGE });

  const knowledgeItems = id ? getItemsByCategoryId(knowledge, { id }) : [];
  const category = id ? getCategoryById(knowledge, { id }) : {};

  return {
    id,
    ...category,
    knowledgeItems,
    adminMode: application.adminMode && !category?.options?.externalSyncProvider,
    showExternalSync: application.adminMode && category?.options?.externalSyncProvider,
    lastUpdated: knowledge.lastUpdated,
    lang: { ...lang.KNOWLEDGE_BUTTONS, ...lang.SEARCH_TYPES },
    langTooltip: lang.ACTION_BUTTON_TOOLTIPS,
  };
};

const mapDispatchToProps = {
  knowledgeSelectItem,
  updateKnowledgeItemsOrder,
};

export const KnowledgeBinder = connect(
  mapStateToProps,
  mapDispatchToProps,
)(KnowledgeBinderComponent);
