import React from 'react';
import {
  CATEGORY_TYPES,
  DEFAULT_CONTENT_TAGS,
  EVENTS,
  ANALYTICS_CONTENT_CATEGORIES_TYPES,
} from 'shared/constants';
import { Popup } from 'src/components/common';
import { Button, Input, Loader, SelectInput, TagInput } from 'src/components/core';
import styles from './admission-category-editor.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getCategoryType, normalizedContains } from 'shared/utils';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { TagsSelector, TAG_SELECTOR_TYPE } from 'src/components/tags/tagSelector';
import { HorizontalChecklistIcon, HorizontalDirectoryChecklistIcon } from 'src/images';
import { PopupFormButtons } from 'src/components/common/popup-form-buttons';
import analyticsService from 'src/helpers/analytics.service';
import { MultipleOwnersSelector } from 'src/components/owners/owners-selector/multiple-owners-selector';

const ALLOWED_CATEGORIES = {
  [CATEGORY_TYPES.CATEGORY]: { label: 'Category' },
  [CATEGORY_TYPES.CHECKS]: {
    label: 'Checklist',
  },
};

const CATEGORY_SCHEMA = Yup.object().shape({
  title: Yup.string().min(2, 'Too Short!').max(200, 'Too Long!').required('Required!'),
  type: Yup.string().required(),
  ownerIds: Yup.array()
    .of(Yup.string())
    .min(1, 'Please assign at least one owner')
    .required('Required!'),
  specialty: Yup.string().nullable(),
  subspecialty: Yup.string().nullable(),
  contentType: Yup.string().nullable(),
  targetAudience: Yup.array().of(Yup.string()),
});

const AdmissionCategoryEditorComponent = React.memo(function AdmissionCategoryEditorComponent({
  entity,
  onFinish,
  createCategory,
  updateCategory,
  locations,
}) {
  let isNew = !entity.id;
  let { isSectionCreation, path, type } = entity;

  const [isLoading, setLoadingState] = React.useState(false);

  const lang = useSelector(getLang('ADMISSION_CATEGORY_EDITOR'));

  const createOrUpdateCategoryAction = React.useCallback(
    async values => {
      setLoadingState(true);

      // TODO: create the category
      let categoryRes;
      const { specialty, subspecialty, contentType, targetAudience, ...valuesData } = values;

      const data = {
        ...valuesData,
        tags: { specialty, subspecialty, contentType, targetAudience },
      };

      if (isNew) {
        categoryRes = await createCategory(data);

        if (categoryRes) {
          analyticsService.track(EVENTS.CATEGORIES.ADD, {
            categoryType: getCategoryType({
              path: categoryRes.path,
              type: categoryRes.type,
              isSectionCreation,
              isNew: true,
            }),
            contentType: ANALYTICS_CONTENT_CATEGORIES_TYPES.CHECKLIST,
            categoryId: categoryRes.id,
            title: categoryRes.title,
            ownerIds: categoryRes.ownerIds,
          });
        }
      } else {
        categoryRes = await updateCategory(data);

        analyticsService.track(EVENTS.CATEGORIES.EDIT, {
          categoryType: getCategoryType({ path: values.path, type: values.type }),
          contentType: ANALYTICS_CONTENT_CATEGORIES_TYPES.CHECKLIST,
          categoryId: values.id,
          title: values.title,
          ownerIds: values.ownerIds,
        });
      }

      onFinish({ ...categoryRes, isSectionCreation });
      setLoadingState(false);
    },
    [createCategory, isNew, isSectionCreation, onFinish, updateCategory],
  );

  const dismiss = React.useCallback(() => {
    // TODO: analytics

    onFinish({});
  }, [onFinish]);

  const { handleChange, handleSubmit, setFieldValue, resetForm, values, errors, dirty, isValid } =
    useFormik({
      validationSchema: CATEGORY_SCHEMA,
      initialValues: {
        type: isSectionCreation ? CATEGORY_TYPES.CATEGORY : CATEGORY_TYPES.CHECKS,
        ...entity.tags,
        ...entity,
        ownerIds: entity.ownerIds ?? [],
      },
      onSubmit: createOrUpdateCategoryAction,
    });

  const selectCategoryType = React.useCallback(
    e => {
      let { value } = e.target;
      setFieldValue('type', value);
    },
    [setFieldValue],
  );

  const resetField = React.useCallback(
    field => {
      const newValues = { values };
      delete newValues.values[field];
      resetForm(newValues);
    },
    [values, resetForm],
  );

  const renderIcon = key => {
    if (key === CATEGORY_TYPES.CATEGORY) {
      return <img src={HorizontalDirectoryChecklistIcon} className={styles.icon} alt={key} />;
    }

    return <img src={HorizontalChecklistIcon} alt={key} className={styles.icon} />;
  };

  const renderCategoryTypes = () => {
    if (!isNew || !path || isSectionCreation) return null;

    return (
      <>
        <p>{lang.SELECT_CATEGORY_TYPE}</p>
        <div className={styles.categoriesTypes}>
          {Object.keys(ALLOWED_CATEGORIES).map(key => (
            <Button
              key={key}
              value={key}
              className={styles.categorySelect}
              onClick={selectCategoryType}
              buttonStyle={key === values.type && 'secondary'}
              size="medium"
            >
              {renderIcon(key)}
              {ALLOWED_CATEGORIES[key].label}
            </Button>
          ))}
        </div>
      </>
    );
  };

  const isChecklistsCategory =
    type === CATEGORY_TYPES.CHECKS || (isNew && values.type === CATEGORY_TYPES.CHECKS);

  return (
    <Popup title={isNew ? lang.ADD_TITLE : lang.EDIT_TITLE} isOpen>
      <form onSubmit={handleSubmit}>
        {renderCategoryTypes()}

        <>
          <label>{lang.INSERT_CATEGORY_NAME[values.type]}</label>
          <Input
            name="title"
            value={values.title}
            error={errors.title}
            onChange={handleChange}
            inputStyle="editor"
            placeholder={lang.INSERT_CATEGORY_NAME_PLACEHOLDER[values.type]}
          />
        </>

        <label>{lang.OWNER}</label>
        <MultipleOwnersSelector
          value={values.ownerIds}
          error={errors.ownerIds}
          placeholder={lang.OWNER_PLACEHOLDER}
          name="ownerIds"
          onChange={setFieldValue}
          bordered
          borderRadius={4}
        />

        {isChecklistsCategory && (
          <>
            <label>{lang.KEYWORDS}</label>
            <TagInput
              value={values.keywords}
              options={DEFAULT_CONTENT_TAGS}
              name="keywords"
              placeholder={lang.KEYWORDS_PLACEHOLDER}
              onChange={setFieldValue}
            />
          </>
        )}

        <TagsSelector
          selectedValues={values}
          errors={errors}
          onChange={setFieldValue}
          resetField={resetField}
          type={TAG_SELECTOR_TYPE.CHECKLIST}
        />

        {!isNew && (
          <>
            <label>{lang.LOCATION}</label>
            <SelectInput
              value={values.path}
              error={errors.path}
              placeholder={lang.LOCATION_PLACEHOLDER}
              options={locations}
              name="path"
              onChange={setFieldValue}
              filterOption={(inputValue, option) =>
                normalizedContains(option?.children, inputValue)
              }
            />
          </>
        )}

        {!isLoading && (
          <PopupFormButtons
            dismiss={dismiss}
            language={{
              DISMISS: lang.DISMISS,
              CREATE_OR_UPDATE: isNew ? lang.CREATE : lang.UPDATE,
            }}
            disableUpdate={!dirty || !isValid}
          />
        )}
        {isLoading && <Loader />}
        {!isNew && <span className={styles.itemId}>Id: {entity?.id}</span>}
      </form>
    </Popup>
  );
});

AdmissionCategoryEditorComponent.defaultProps = {
  entity: {
    path: null,
    isSectionCreation: false,
  },
  createCategory: () => {},
  updateCategory: () => {},
  onFinish: () => {},
  locations: [],
};

export { AdmissionCategoryEditorComponent };
