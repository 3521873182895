import React from 'react';
import {
  Lang,
  DEFAULT_CONTENT_TAGS,
  TAGS_LAB_CONTENT_TYPE,
  EVENTS,
  ENTITY_TYPES,
} from 'shared/constants';
import { Popup, UploadDocument } from 'src/components/common';
import styles from './lab-value-editor.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Input, Loader, MrkdwnEditor, TagInput } from 'src/components/core';
import { MultipleOwnersSelector } from 'src/components/owners/owners-selector/multiple-owners-selector';
import { TagsSelector, TAG_SELECTOR_TYPE } from 'src/components/tags/tagSelector';
import { DrugClassSelector } from 'src/components/drugClass/drugClassSelector';
import { PopupFormButtons } from 'src/components/common/popup-form-buttons';
import analyticsService from 'src/helpers/analytics.service';

const LAB_VALUE_SCHEMA = Yup.object().shape({
  title: Yup.string().min(2, 'Too Short!').max(200, 'Too Long!').required('Required!'),
  value: Yup.string().min(2, 'Too Short!').required('Required!'),
  keywords: Yup.array().of(Yup.string()),
  info: Yup.string(),
  reference: Yup.string().url(),
  url: Yup.string().url(),
  ownerIds: Yup.array()
    .of(Yup.string())
    .min(1, 'Please assign at least one owner')
    .required('Required!'),
  specialty: Yup.string().nullable(),
  subspecialty: Yup.string().nullable(),
  contentType: Yup.string().nullable(),
  targetAudience: Yup.array().of(Yup.string()),
  drugClass: Yup.string().nullable(),
});

const LabValueEditorComponent = React.memo(function LabValueEditorComponent({
  lang,
  value,
  categoryId,
  createLabItem,
  updateLabItem,
  onFinish,
}) {
  const isNew = !value.id;
  const [isLoading, setLoadingState] = React.useState(false);

  const updateOrGenerateValue = React.useCallback(
    async item => {
      setLoadingState(true);

      const { specialty, subspecialty, contentType, targetAudience, ...restData } = item;

      const itemData = {
        item: {
          ...restData,
          tags: { specialty, subspecialty, contentType, targetAudience },
          drugClass: contentType === TAGS_LAB_CONTENT_TYPE.DRUG_DOSAGE ? item.drugClass : null,
        },
      };

      if (categoryId) {
        itemData.categoryId = categoryId;
      }

      // TODO: analytics
      if (isNew) {
        analyticsService.track(EVENTS.CONTENT.UPLOAD, {
          ...itemData,
          entityType: ENTITY_TYPES.compendium,
        });
        await createLabItem(itemData);
      } else {
        itemData.id = value.id;
        analyticsService.track(EVENTS.CONTENT.UPDATE, {
          ...itemData,
          entityType: ENTITY_TYPES.compendium,
        });

        await updateLabItem(itemData);
      }

      onFinish(true);
      setLoadingState(false);
    },
    [categoryId, createLabItem, isNew, onFinish, updateLabItem, value.id],
  );

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    values,
    errors,
    touched,
    dirty,
    isValid,
  } = useFormik({
    validationSchema: LAB_VALUE_SCHEMA,
    initialValues: {
      ...value,
      ...value.tags,
      drugClass:
        value?.tags?.contentType === TAGS_LAB_CONTENT_TYPE.DRUG_DOSAGE ? value.drugClass : null,
    },
    onSubmit: updateOrGenerateValue,
  });

  const dismiss = React.useCallback(() => {
    // TODO: analytics

    onFinish();
  }, [onFinish]);

  const resetField = React.useCallback(
    field => {
      const newValues = { values };
      delete newValues.values[field];
      resetForm(newValues);
    },
    [values, resetForm],
  );

  return (
    <Popup title={isNew ? lang.CREATE_NEW_TITLE : lang.UPDATE_TITLE} isOpen>
      <form onSubmit={handleSubmit}>
        <label>{lang.ITEM_TITLE}</label>
        <Input
          inputStyle="editor"
          name="title"
          value={values.title}
          error={errors.title}
          touched={touched.title}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={lang.ITEM_TITLE_PLACEHOLDER}
        />

        <label>{lang.ITEM_VALUES}</label>
        <MrkdwnEditor
          name="value"
          value={values.value}
          error={errors.value}
          touched={touched.value}
          onChange={setFieldValue}
          placeholder={lang.ITEM_VALUES_PLACEHOLDER}
        />

        <label>{lang.OWNER}</label>
        <MultipleOwnersSelector
          value={values.ownerIds}
          error={errors.ownerIds}
          placeholder={lang.OWNER_PLACEHOLDER}
          name="ownerIds"
          onChange={setFieldValue}
          bordered
          borderRadius={4}
        />

        <label>{lang.ITEM_KEYWORDS}</label>
        <TagInput
          value={values.keywords}
          options={DEFAULT_CONTENT_TAGS}
          name="keywords"
          placeholder={lang.ITEM_KEYWORDS_PLACEHOLDER}
          onChange={setFieldValue}
        />

        <TagsSelector
          selectedValues={values}
          errors={errors}
          onChange={setFieldValue}
          resetField={resetField}
          type={TAG_SELECTOR_TYPE.LAB}
        />

        {values.contentType === TAGS_LAB_CONTENT_TYPE.DRUG_DOSAGE && (
          <>
            <label>{lang.DRUG_CLASS}</label>
            <DrugClassSelector
              value={values.drugClass}
              error={errors.drugClass}
              placeholder={lang.DRUG_CLASS_PLACEHOLDER}
              name="drugClass"
              onChange={setFieldValue}
              resetField={resetField}
            />
          </>
        )}

        <label>{lang.ITEM_INFO}</label>
        <MrkdwnEditor
          name="info"
          value={values.info}
          error={errors.info}
          touched={touched.info}
          onChange={setFieldValue}
          placeholder={lang.ITEM_INFO_PLACEHOLDER}
        />

        <label>{lang.ITEM_REFERENCE}</label>
        <UploadDocument
          setFieldValue={setFieldValue}
          name="reference"
          value={values.reference}
          error={errors.reference}
          touched={touched.reference}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={lang.ITEM_REFERENCE_PLACEHOLDER}
        />

        <label>{lang.ITEM_URL}</label>
        <UploadDocument
          setFieldValue={setFieldValue}
          name="url"
          value={values.url}
          error={errors.url}
          touched={touched.url}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={lang.ITEM_URL_PLACEHOLDER}
        />

        {!isLoading && (
          <PopupFormButtons
            dismiss={dismiss}
            language={{
              DISMISS: lang.DISMISS,
              CREATE_OR_UPDATE: isNew ? lang.CREATE : lang.UPDATE,
            }}
            disableUpdate={!dirty || !isValid}
          />
        )}
        {isLoading && <Loader />}
        {!isNew && <span className={styles.itemId}>Id: {value?.id}</span>}
      </form>
    </Popup>
  );
});

LabValueEditorComponent.defaultProps = {
  lang: Lang.LAB_VALUE_EDITOR,
  value: {},
  categoryId: null,
  createLabItem: () => {},
  uploadDocument: () => {},
  updateLabItem: () => {},
  onFinish: () => {},
};

export { LabValueEditorComponent };
