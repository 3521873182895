import { connect } from 'react-redux';
import { BreadcrumbsComponent } from './Breadcrumbs.component';
import { generateBreadcrumbs } from 'shared/store/selectors/admission.selector';
import { ROUTES } from 'shared/constants';

const mapStateToProps = ({ admission }, { pathParts }) => {
  return {
    path: generateBreadcrumbs(admission, { pathParts }),
    categoryPath: ROUTES.ADMISSION_CATEGORIES,
    rootPath: ROUTES.ADMISSION,
    rootTitle: 'Checklist',
  };
};

export const AdmissionBreadcrumbs = connect(mapStateToProps)(BreadcrumbsComponent);
