import styles from './render-chat-messages.module.scss';
import React from 'react';
import { MessageReferences } from './render-references';
import { ResponseActions } from './response-actions';
import { MessageResources } from './render-resources';
import { useSelector } from 'react-redux';
import { getKnowledgeItemById } from 'shared/store/selectors/knowledge.selector';
import ReactMarkdown from 'react-markdown';
import { getOwnersByIDs } from 'shared/store/selectors/owners.selector';
import { useChatConversation } from '../../hooks/use-chat-conversation';
import { ANSWER_STATE, HISTORY_STATE } from 'shared/constants';
import { getWorkspaceById } from 'shared/store/selectors/workspace.selector';
import { isThereOnlyOneWorkspace } from 'shared/store/selectors/workspace.selector';
import { formatFullName } from 'shared/utils';

export function RenderAnswer({ message, renderAvatar, ref }) {
  const { lang } = useChatConversation();

  const knowledgeItemId = message?.references && message.references[0]?.knowledgeItemId;
  const knowledgeItem = useSelector(state => getKnowledgeItemById(state, knowledgeItemId));
  const owners = useSelector(getOwnersByIDs(knowledgeItem?.ownerIds || []));
  const workspace = useSelector(getWorkspaceById(knowledgeItem?.workspaceId));
  const isOnlyOneWorkspace = useSelector(isThereOnlyOneWorkspace);

  const isResourcesOnly = message.state === ANSWER_STATE.ONLY_RESOURCES_FOUND;

  const isRegenerated = message.historyState === HISTORY_STATE.REGENERATED;

  const isHistoryMessage = !message.id;

  return (
    <div className={styles.message} ref={ref}>
      <div className={styles.avatar}>{renderAvatar(false)}</div>

      <div className={styles.content}>
        <div className={styles.botNameTitle}>
          <h4>{lang.BOT_NAME}</h4>
          {isRegenerated && <span>{`(${lang.REGENERATED})`}</span>}
        </div>
        <ReactMarkdown className={styles.messageContent}>{message.content}</ReactMarkdown>

        <div className={styles.details}>
          {knowledgeItem && !isResourcesOnly && !message.error && (
            <div className={styles.ownerSection}>
              <span>{`${lang.REASONING_KNOWLEDGE_ITEM}`}</span>
              <a href={`/knowledge/items/${knowledgeItem.id}`}>{knowledgeItem.title}</a>
              {!isOnlyOneWorkspace && <span>{` (${workspace.name})`}</span>}
              {owners && owners.length > 0 && (
                <>
                  <span>{` ${lang.VETTED_BY}`}</span>
                  {owners.map((owner, index) => (
                    <div key={index} style={styles.ownersWrapper}>
                      {index > 0 && <span>{`, `}</span>}
                      <span className={styles.ownerFullName}>{formatFullName(owner)}</span>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}

          {!message.error && (
            <>
              <MessageReferences message={message} />

              <MessageResources
                message={message}
                excludeKnowledgeItemId={!isResourcesOnly ? knowledgeItemId : ''}
              />

              {!isHistoryMessage && <ResponseActions message={message} />}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
