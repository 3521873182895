import { connect } from 'react-redux';
import { fetchUsers, setPermission } from 'shared/store/actions/users-management.actions';
import { getOwnersList } from 'shared/store/selectors/owners.selector';
import { OwnersManagementPageComponent } from './owners-management.component';

const mapStateToProps = ({ lang, owners }) => {
  return {
    lang: lang.OWNERS_MANAGEMENT,
    owners: getOwnersList(owners),
  };
};

export const OwnersManagementPage = connect(mapStateToProps, { fetchUsers, setPermission })(
  OwnersManagementPageComponent,
);
