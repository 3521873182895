import React from 'react';
import styles from './owners-management.module.scss';
import { ScreenTitle } from 'src/components/design-system';
import { Col, Row } from 'antd';
import Markdown from 'react-markdown';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom-v5-compat';
import { ROUTES } from 'shared/constants';

export function OwnersManagementPageComponent({ lang, owners }) {
  return (
    <div className={classNames(styles.root, 'new-design-system')}>
      <ScreenTitle title={lang.HEADER} />

      <Row gutter={[16, 16]} className={classNames(styles.mainContent, 'compressive-text')}>
        <Col xl={8} lg={12}>
          <h3>{lang.LEFT_EXPLAIN_HEADER}</h3>
          <Markdown className={styles.content}>{lang.LEFT_EXPLAIN_CONTENT}</Markdown>
        </Col>
        <Col
          xl={8}
          offset={{
            xl: 1,
          }}
          lg={12}
        >
          <h3>{lang.RIGHT_EXPLAIN_HEADER}</h3>
          <Markdown className={styles.content}>{lang.RIGHT_EXPLAIN_CONTENT}</Markdown>
        </Col>
      </Row>
      <NavLink to={ROUTES.USERS_MANAGEMENT} className={styles.redirect}>
        {lang.REDIRECT_TO_USERS_MANAGEMENT}
      </NavLink>
    </div>
  );
}
