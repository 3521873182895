import React, { useCallback, useEffect } from 'react';
import { Button } from '../../components/design-system/buttons';
import { SelectBox, TextInput } from '../../components/design-system/forms';
import { SectionTitle } from '../../components/design-system/titles';
import styles from './user-profile.module.scss';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  useProfileOptions,
  PROFESSIONS_WITH_ROLES,
  ROLES_WITH_LEVEL_OF_TRAINING,
} from 'shared/hooks/profile.hooks';
import { DateTime } from 'luxon';
import classnames from 'classnames';
import { Lang, EVENTS, TERMS_AND_CONDITIONS_LINK } from 'shared/constants';
import { notification } from 'antd';
import analyticsService from '../../helpers/analytics.service';

const PROFILE_SCHEMA = Yup.object().shape({
  firstName: Yup.string().required('Required!'),
  lastName: Yup.string().required('Required!'),
  phone: Yup.string(),
  pager: Yup.string(),
  cisco: Yup.string(),
  profession: Yup.string().required('Required!'),
  language: Yup.string().required('Required!'),
  timezone: Yup.string().required('Required!'),
  role: Yup.string().when('profession', ([profession], schema) =>
    PROFESSIONS_WITH_ROLES.includes(profession) ? schema.required('Required!') : schema,
  ),
  levelOfTraining: Yup.string().when('role', ([role], schema) =>
    ROLES_WITH_LEVEL_OF_TRAINING.includes(role) ? schema.required('Required!') : schema,
  ),
  department: Yup.string().required('Required!'),
  rotation: Yup.string().nullable(),
});

const UserProfileComponent = ({
  lang = Lang.USER_PROFILE,
  user,
  selectedLang,
  updateUserData,
  snoozeProfile,
  isPopup = false,
  onClose = () => {},
  isMandatory = false,
  showTermsAndConditions = false,
}) => {
  const canSnooze = user?.snooze?.counter < 5;
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    analyticsService.track(EVENTS.USER_PROFILE.OPEN, {
      id: user.id.toString(),
      email: user.email,
    });
  }, [user]);

  const openNotification = useCallback(() => {
    api.success({
      message: lang.NOTIFICATION_SUCCESS,
      placement: 'bottomRight',
    });
  }, [api, lang]);

  const onFinish = useCallback(() => {
    if (isPopup) {
      onClose();
    }
  }, [isPopup, onClose]);

  const onCancel = useCallback(() => {
    if (isPopup) {
      analyticsService.track(EVENTS.USER_PROFILE.SNOOZE, {
        id: user.id.toString(),
        email: user.email,
      });

      snoozeProfile({ id: user?.id });
    }
    onFinish();
  }, [isPopup, snoozeProfile, onFinish, user]);

  const updateProfile = useCallback(
    async data => {
      const res = await updateUserData({ id: user.id, user: data });

      if (!res.error) {
        analyticsService.track(
          !user.profileCompletedAt ? EVENTS.USER_PROFILE.COMPLETE : EVENTS.USER_PROFILE.UPDATE,
          {
            id: user.id.toString(),
            email: user.email,
            profession: data?.profession,
            role: data?.role,
            levelOfTraining: data?.levelOfTraining,
            department: data?.department,
            rotation: data?.rotation,
            language: data?.language,
            timezone: data?.timezone,
          },
        );

        openNotification();
      }

      onFinish();
    },
    [onFinish, openNotification, updateUserData, user],
  );

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: PROFILE_SCHEMA,
    initialValues: {
      ...user,
      ...user.professional,
      timezone: user?.timezone ?? DateTime.local().zoneName,
      language: user?.language ?? selectedLang,
    },
    onSubmit: updateProfile,
  });

  const resetField = useCallback(
    field => {
      const newValues = { values };
      delete newValues.values[field];
      resetForm(newValues);
    },
    [values, resetForm],
  );
  const handleDependentFieldChange = useCallback(
    (field, dependentField, value) => {
      if (!value || value !== values[value]) {
        resetField(dependentField);
      }
      setFieldValue(field, value);
    },
    [setFieldValue, resetField, values],
  );

  const {
    departmentOptions,
    rotationOptions,
    professionOptions,
    roleOptions,
    levelOftrainingOptions,
    timezoneOptions,
    languageOptions,
  } = useProfileOptions({
    profession: values.profession,
    role: values.role,
    department: values.department,
  });

  return (
    <>
      {contextHolder}
      <form onSubmit={handleSubmit}>
        <div className={styles.section}>
          {!isPopup && <SectionTitle title={lang.PROFESSIONAL_INFO} />}
          <div className={styles.row}>
            <SelectBox
              label={lang.DEPARTMENT}
              placeholder={lang.DEPARTMENT}
              name="department"
              value={values.department}
              error={errors.department}
              onChange={setFieldValue}
              options={departmentOptions}
            />
            <SelectBox
              label={lang.ROTATION}
              placeholder={lang.ROTATION}
              name="rotation"
              value={values.rotation}
              error={errors.rotation}
              onChange={setFieldValue}
              options={rotationOptions}
            />
          </div>
          <div className={styles.row}>
            <SelectBox
              label={lang.PROFESSION}
              placeholder={lang.PROFESSION}
              name="profession"
              value={values.profession}
              error={errors.profession}
              onChange={(field, value) => handleDependentFieldChange('profession', 'role', value)}
              options={professionOptions}
            />
            <SelectBox
              label={lang.ROLE}
              placeholder={lang.ROLE}
              name="role"
              value={values.role}
              error={errors.role}
              onChange={(field, value) =>
                handleDependentFieldChange('role', 'levelOfTraining', value)
              }
              options={roleOptions ?? []}
              disabled={!roleOptions}
            />
            <SelectBox
              label={lang.LEVEL_OF_TRAINING}
              placeholder={lang.LEVEL_OF_TRAINING}
              name="levelOfTraining"
              value={values.levelOfTraining}
              error={errors.levelOfTraining}
              onChange={setFieldValue}
              options={levelOftrainingOptions ?? []}
              disabled={!levelOftrainingOptions}
            />
          </div>
        </div>

        <div className={classnames([styles.section, isPopup && styles.noMarginTop])}>
          {!isPopup && <SectionTitle title={lang.PERSONAL_INFO} />}
          <div className={styles.row}>
            <TextInput
              label={lang.FIRST_NAME}
              placeholder={lang.FIRST_NAME}
              name="firstName"
              value={values.firstName}
              error={errors.firstName}
              touched={touched.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextInput
              label={lang.LAST_NAME}
              placeholder={lang.LAST_NAME}
              name="lastName"
              value={values.lastName}
              error={errors.lastName}
              touched={touched.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextInput
              label={lang.PHONE}
              placeholder={lang.PHONE}
              name="phone"
              value={values.phone}
              error={errors.phone}
              touched={touched.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextInput
              label={lang.PAGER}
              placeholder={lang.PAGER}
              name="pager"
              value={values.pager}
              error={errors.pager}
              touched={touched.pager}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextInput
              label={lang.CISCO}
              placeholder={lang.CISCO}
              name="cisco"
              value={values.cisco}
              error={errors.cisco}
              touched={touched.cisco}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>

        {!isPopup && (
          <div className={styles.section}>
            <SectionTitle title={lang.SYSTEM_INFO} />
            <div className={styles.row}>
              <SelectBox
                label={lang.TIMEZONE}
                placeholder={lang.TIMEZONE}
                name="timezone"
                value={values.timezone}
                error={errors.timezone}
                onChange={setFieldValue}
                options={timezoneOptions}
              />
              <SelectBox
                label={lang.LANGUAGE}
                placeholder={lang.LANGUAGE}
                name="language"
                value={values.language}
                error={errors.language}
                onChange={setFieldValue}
                options={languageOptions}
              />
            </div>
          </div>
        )}

        {isPopup && showTermsAndConditions && (
          <p className={styles.disclaimer}>
            {lang.TERMS_AND_CONDITIONS_DISCLAIMER}
            <a href={TERMS_AND_CONDITIONS_LINK} target="_blank" rel="noreferrer">
              {lang.TERMS_AND_CONDITIONS}
            </a>
          </p>
        )}
        <div className={classnames([styles.buttonsContainer, isPopup && styles.noWidth])}>
          <Button
            onClick={handleSubmit}
            type="primary"
            size="large"
            disabled={Object.keys(errors).length}
          >
            {lang.SAVE}
          </Button>
          {isPopup && !isMandatory && (
            <Button onClick={onCancel} type="default" size="large" disabled={isPopup && !canSnooze}>
              {isPopup ? lang.SNOOZE : lang.DISMISS}
            </Button>
          )}
        </div>
      </form>
    </>
  );
};

export { UserProfileComponent };
