import React, { useMemo } from 'react';
import styles from './workspace-selector-input.module.scss';
import { Select } from 'src/components/design-system/forms/select.component';
import { useSelector } from 'react-redux';
import { getWorkspaces } from 'shared/store/selectors/workspace.selector';

export function WorkspaceSelectorInput({ lang, onChange, error, ...props }) {
  const workspaces = useSelector(getWorkspaces);

  const workspacesOptions = useMemo(() => {
    return workspaces.map(({ id, name }) => ({ value: id, label: name }));
  }, [workspaces]);

  return (
    <div className={styles.root}>
      <Select
        mode="multiple"
        error={error}
        onChange={onChange}
        options={workspacesOptions}
        {...props}
      />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
}
