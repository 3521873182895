import React from 'react';
import {
  DocumentIcon,
  ImageIcon,
  ItemBinderIcon,
  LinkIcon,
  PresentationIcon,
  VideoIcon,
  WebsiteLinkIcon,
} from 'src/images';
import styles from './knowledge-item-row.module.scss';
import { formatFullName, isVideoUrl } from 'shared/utils';
import { KNOWLEDGE_TYPES } from 'shared/constants';
import { useSelector } from 'react-redux';
import { getOwnersByIDs } from 'shared/store/selectors/owners.selector';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useDateFormatter } from 'shared/hooks/dates.hooks';
import classNames from 'classnames';
import { RenderLabels } from 'src/components/common/categories/components';
import { getWorkspaces } from 'shared/store/selectors/workspace.selector';

const TYPE_TO_ICON_MAPPER = {
  content: DocumentIcon,
  pdf: DocumentIcon,
  excel: DocumentIcon,
  document: DocumentIcon,
  image: ImageIcon,
  video: VideoIcon,
  presentation: PresentationIcon,
  link: LinkIcon,
  'external-link': WebsiteLinkIcon,
};

const KnowledgeItemRow = ({ item, onSelect, disabled = false, selected = false }) => {
  const {
    title,
    link,
    thumbnail,
    type,
    subtitle,
    ownerIds,
    updatedAt,
    labels,
    workspaceId,
    createdAt,
  } = item;
  const workspaces = useSelector(getWorkspaces);
  const knowledgeWorkspace = workspaces.find(w => w.id === workspaceId);
  const owners = useSelector(getOwnersByIDs(ownerIds));
  const { formatLongDate } = useDateFormatter();
  const lang = useSelector(getLang('CONTENT_SELECTOR'));
  const showWorkspace = workspaces.length > 1 && knowledgeWorkspace;
  const renderThumbnail = () => {
    if (!thumbnail) {
      const isVideo = isVideoUrl(link);
      const Icon = isVideo
        ? TYPE_TO_ICON_MAPPER[KNOWLEDGE_TYPES.VIDEO]
        : TYPE_TO_ICON_MAPPER[type] || ItemBinderIcon;

      return <img src={Icon} className={styles.itemIcon} alt={`${type}-knowledge-item`} />;
    }

    return (
      <div className={styles.thumbnailWrapper}>
        <img src={thumbnail} alt={title} className={styles.thumbnail} />
      </div>
    );
  };

  return (
    <div
      className={classNames([
        'new-design-system',
        styles.container,
        disabled && styles.disabled,
        selected && styles.selected,
      ])}
      onClick={() => !disabled && onSelect(item)}
    >
      <div className={styles.item}>
        <div className={styles.itemContent}>
          {renderThumbnail()}
          <div className={styles.textContainer}>
            <p className={styles.title}>{title}</p>
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
            <div className={styles.footer}>
              {showWorkspace && (
                <span>
                  {lang.WORKSPACE} <strong>{knowledgeWorkspace.name}</strong>
                </span>
              )}
              {showWorkspace && owners.length > 0 && <span className={styles.divider}>|</span>}
              {owners.length > 0 && (
                <>
                  {lang.VETTED_BY}{' '}
                  {owners.map((owner, index) => (
                    <span key={index}>
                      <strong>{formatFullName(owner)}</strong>
                      {','}
                    </span>
                  ))}
                  {formatLongDate(updatedAt || createdAt)}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {labels && <RenderLabels labels={labels} styles={styles} />}
    </div>
  );
};

export { KnowledgeItemRow };
