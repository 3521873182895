import _ from 'lodash';
import { DateTime } from 'luxon';
import { CATEGORY_TYPES, KNOWLEDGE_TO_BE_EXPIRED_ITEMS_IN_DAYS } from '../../constants';
import { getParentIdByPath } from '../../utils';
import workspaceService from '../../services/workspace.service';
import { getUser } from './user.selector';
import { hasPermissionsToEditItem } from './iam.selector';

export const UNASSIGNED = 'unassigned';

const filterByWorkspace = (item, workspaceId) =>
  item?.workspaceId ? item.workspaceId === workspaceId : true;

export const getByIdCategory = (category, idToSearch) => {
  if (category.children && category.children.length !== 0) {
    let result;
    category.children.forEach(item => {
      if (result) return false;
      let res = getByIdCategory(item, idToSearch);

      if (res) result = res;
    });

    return result;
  }

  if (category.knowledge_item_medias && category.knowledge_item_medias !== 0) {
    return category.knowledge_item_medias.find(({ id }) => idToSearch === id);
  }
};

export const getKnowledgeItemById = ({ knowledge }, id) => {
  return getItemById(knowledge, { id });
};

export const getRoot = ({ categories }) => {
  //support orgs with and without workspaces
  if (!workspaceService.hasWorkspaces()) {
    if (!categories) return {};
    return categories.find(category => category.path === 'root');
  }

  const workspaceId = workspaceService.getSelectedWorkspaceId();

  if (!categories || !workspaceId) return {};
  return (
    categories.find(category => category.path === 'root' && category.workspaceId === workspaceId) ??
    {}
  );
};

export const getCategoriesByPath = ({ categories, categoriesById }, path) => {
  if (_.isNull(categories)) return null;

  let parentId = getParentIdByPath(path);

  return getCategoriesById({ categoriesById }, parentId);
};

export const getCategoriesById = ({ categoriesById }, id) => {
  if (_.isNull(categoriesById)) return null;

  if (!categoriesById[id]) return null;

  return categoriesById[id]?.subcategories.map(i => getCategoryById({ categoriesById }, { id: i }));
};

export const getCategoryById = ({ categoriesById }, { id }) => {
  return !_.isNull(categoriesById) ? categoriesById[id] ?? {} : {};
};

export const getItemById = ({ itemsById }, { id }) => {
  return itemsById?.[id] || null;
};

export const getItemsByIds = ({ itemsById }, ids) => {
  return ids?.map(id => itemsById?.[id] || {}).filter(i => i?.id) || [];
};

export const generateBreadcrumbs = ({ categoriesById }, { pathParts = [] }) => {
  return pathParts ? pathParts.map(id => categoriesById[id]) : [];
};

export const getItemsByCategoryId = ({ itemsById, categoriesById }, { id }) => {
  const { knowledgeItems = [] } = !_.isNull(categoriesById) ? categoriesById[id] ?? {} : {};

  return knowledgeItems
    .map(({ id, linked, ownerIds = null, ...itemData }) => {
      const item = itemsById[id] || null;

      if (!!item && linked) {
        return { ...item, linked, linkOwnerIds: itemData.linkOwnerIds ?? ownerIds };
      }
      return item;
    })
    .filter(i => !!i);
};

export const getExpiredItems = ({ items }, { items: owners }) => {
  const expirationDate = DateTime.now().plus({
    days: KNOWLEDGE_TO_BE_EXPIRED_ITEMS_IN_DAYS,
  });

  const groupedItems = items
    ?.filter(({ expiresAt }) => expiresAt && expirationDate.diff(DateTime.fromISO(expiresAt)) > 0)
    .sort((a, b) => DateTime.fromISO(b.expiresAt).diff(DateTime.fromISO(a.expiresAt)))
    // create a map of ownerIds and array of items
    .reduce((acc, item) => {
      // Handle multiple owners
      const ownerIds = item.ownerIds && item.ownerIds.length > 0 ? item.ownerIds : [UNASSIGNED];

      ownerIds.forEach(ownerId => {
        const ownerKey = owners[ownerId] ? ownerId : UNASSIGNED;
        if (!acc[ownerKey]) {
          acc[ownerKey] = [];
        }
        acc[ownerKey].push(item);
      });

      return acc;
    }, {});

  // sort the map by number of items
  return Object.fromEntries(
    Object.entries(groupedItems || []).sort((a, b) => b[1].length - a[1].length),
  );
};

export const getKnowledgeItemsOpenEventsForOrganization = ({
  itemsById,
  itemsOpenEventsForOrganization,
}) => {
  return itemsOpenEventsForOrganization?.map(item => itemsById?.[item.id] || null).filter(i => !!i);
};

export const getKnowledgeItemsOpenEventsForUser = ({ itemsById, itemsOpenEventsForUser, id }) => {
  return itemsOpenEventsForUser?.map(item => itemsById?.[item.id] || null).filter(i => !!i);
};

export const getLocations = ({ categories, categoriesById }, { type = 'category', path }) => {
  const workspaceId = workspaceService.getSelectedWorkspaceId();
  const filteredCategories = categories.filter(category =>
    filterByWorkspace(category, workspaceId),
  );
  let locations = [];

  // check if need to section or category
  if (path.split(',').length % 2 !== 0) {
    locations = filteredCategories.filter(
      i => i.type === type && i.pathParts?.length % 2 === 0 && !!i.path,
    );

    let root = getRoot({ categories: filteredCategories });
    locations.unshift({ fullPath: root.id, title: root.title });
  } else {
    locations = filteredCategories.filter(i => i.type === type && i.pathParts?.length % 2 !== 0);
  }

  return locations.map(item => {
    let path = generateBreadcrumbs({ categoriesById }, { pathParts: item.pathParts }).map(
      t => t?.title,
    );

    path = [...path, item.title].reverse();

    // Remove knowledge base root
    if (path.length > 1) {
      path.pop();
    }

    return {
      id: item.fullPath,
      value: path.join(' | '),
    };
  });
};

export const getLocationsOfKnowledge = ({ categories, categoriesById }) => {
  const workspaceId = workspaceService.getSelectedWorkspaceId();

  let locations = categories
    .filter(category => filterByWorkspace(category, workspaceId))
    .filter(i => i.type === 'knowledge');

  return locations.map(item => {
    let path = generateBreadcrumbs({ categoriesById }, { pathParts: item.pathParts }).map(
      t => t?.title,
    );

    path = [...path, item.title].reverse();

    // Remove knowledge base root
    if (path.length > 1) {
      path.pop();
    }

    return {
      id: item.id,
      value: path.join(' | '),
    };
  });
};

export const getKnowledgeParentsByItemId = ({ categories }, { id }) => {
  return categories.filter(
    cat => cat.type === 'knowledge' && !!cat.knowledgeItems.find(item => item.id === id),
  );
};

export const isKnowledgeReady = ({ knowledge }) => knowledge.isReady;

export const getItemCategoryId = ({ knowledge }, { id }) => {
  return knowledge.categories?.find(cat => cat?.knowledgeItems?.find(item => item.id === id))?.id;
};

export const getKeywordsByItemId = ({ itemsById }, { id }) => {
  return itemsById[id]?.keywords || [];
};

export const isParentSharedToHub =
  path =>
  ({ knowledge }) => {
    const { categoriesById } = knowledge;
    const id = getParentIdByPath(path);
    if (!id) return false;

    const parent = categoriesById[id];
    if (!parent) return false;

    return parent.shared?.isShared ?? false;
  };

/**
 * Check if the user is the owner of the knowledge item before action on it and its children
 *
 * @param {ReduxState} state
 * @param {{ id: ObjectId }} { id }
 * @return {Boolean} if the user is the owner of the knowledge item and its children
 */
export function isKnowledgeOwner(state, { id }) {
  if (!id) return false;

  const hasPermissions = hasPermissionsToEditItem(state);

  // Check if the user has permission to bypass the owner restriction
  if (hasPermissions) return true;

  // Get user id
  const { id: userId } = getUser(state);

  // check children ownership
  return checkChildrenOwnership(state, id, userId);
}

/**
 * Check if the user is the owner of the category and all of its children
 * @param {ReduxState} State
 * @param {ObjectId} id
 * @param {ObjectId} userId
 * @returns {Boolean} if the user is the owner of the category and all of its children
 */
function checkChildrenOwnership(state, id, userId) {
  // get category by id
  const item = getCategoryById(state.knowledge, { id });

  // check if the category is owned by the user
  if (item.ownerIds && !item.ownerIds.includes(userId)) {
    return false;
  }

  if (item.type === CATEGORY_TYPES.CATEGORY) {
    // recursively check if all of the subcategories are owned by the user
    return item.subcategories.every(subcategoryId => {
      return checkChildrenOwnership(state, subcategoryId, userId);
    });
  }

  return item.knowledgeItems.every(({ id, linked, ownerIds: linkOwnerIds }) => {
    // Check if the knowledge item is linked
    if (linked) {
      return linkOwnerIds.includes(userId);
    }

    const knowledgeItem = getItemById(state.knowledge, { id });
    if (!knowledgeItem) return true;

    return knowledgeItem?.ownerIds.includes(userId);
  });
}

/**
 * Check if the user have permissions to edit the item
 *
 * @param {ReduxState} state
 * @param {{ ownerIds: string[], linkOwnerIds: string[], linked: boolean, ownerIds: string[] }}
 * @return {boolean} if the user have permissions to edit the item
 */
export function haveEditPermissions(state, { linkOwnerIds, linked, ownerIds }) {
  const hasPermissions = hasPermissionsToEditItem(state);

  if (hasPermissions) return true;

  const { id: userId } = getUser(state);

  if (linked) {
    return linkOwnerIds.includes(userId);
  }

  if (ownerIds && ownerIds.length > 0) return ownerIds.includes(userId);

  return false;
}
