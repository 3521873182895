import React from 'react';
import styles from './knowledge-info.module.scss';
import moment from 'moment';
import { Lang, WARNING_ABOUT_TO_EXPIRY_IN_DAYS, EVENTS } from 'shared/constants';
import { Button } from 'src/components/core';
import { ExclamationMark } from 'src/images';
import { theme } from 'shared/constants/theme';
import { Popup } from 'src/components/common';
import { Breadcrumbs } from 'src/components/breadcrumbs';
import { TagsIcons } from 'src/components/tags/tagsIcons';
import analyticsService from 'src/helpers/analytics.service';
import { TextTag } from 'src/components/tags/text-tags';
import { useDateFormatter } from 'shared/hooks/dates.hooks';
import { Contributors } from 'src/components/knowledge-content/components/contributors';
import { formatFullName } from 'shared/utils';

const KnowledgeInfoComponent = React.memo(function KnowledgeInfoComponent({
  title,
  itemId,
  lang,
  owners,
  expires_at,
  expiresAt,
  file_updated_at,
  fileUpdatedAt,
  updatedAt,
  knowledgeSetItemDetails,
  ownerObject,
  parents,
  knowledgeSelectItem,
  tags,
  contributors = [],
}) {
  const { formatLongDate } = useDateFormatter();
  const close = React.useCallback(() => {
    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.CLOSE_INFORMATION, {
      id: itemId,
      title,
      ownerId: ownerObject.id,
      ...tags,
    });
    knowledgeSetItemDetails(null);
  }, [knowledgeSetItemDetails, itemId, tags, title, ownerObject]);

  const onNavigateToCategory = id => {
    close();
    knowledgeSelectItem(id);
  };

  if (!itemId) {
    return null;
  }

  let expiryDate = moment(expires_at || expiresAt);
  let daysFromExpiry = expiryDate.diff(moment(), 'days');

  let lastUpdated = file_updated_at || fileUpdatedAt || updatedAt;

  let expiryStyle = styles.contentExpiryDateGood;
  let isShowExclamationMark = false;

  if (daysFromExpiry < WARNING_ABOUT_TO_EXPIRY_IN_DAYS) {
    isShowExclamationMark = true;
    expiryStyle = styles.contentExpired;
  }

  const renderOwner = () => {
    return (
      <div className={styles.detail}>
        <h6>{lang.VALIDATED_BY}</h6>
        <div className={styles.contentDetails}>
          {owners.map((owner, index) => {
            return (
              <>
                {index > 0 && ', '}
                <a key={owner.id} href={`mailto:${owner.email}`}>
                  {formatFullName(owner)}
                </a>
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const ownerByIdRender = () => {
    if (!ownerObject.id) return null;

    let { firstName, lastName, email, phone } = ownerObject;

    return (
      <>
        {!!firstName && (
          <div className={styles.detail}>
            <h6>{lang.VALIDATED_BY}</h6>
            <span>
              {firstName} {lastName}
            </span>
          </div>
        )}

        {!!email && (
          <div className={styles.detail}>
            <h6>{lang.EMAIL}</h6>
            <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
              <span>{email}</span>
            </a>
          </div>
        )}

        {!!phone && (
          <div className={styles.detail}>
            <h6>{lang.PHONE}</h6>
            <span>{phone}</span>
          </div>
        )}
      </>
    );
  };

  const renderTags = () => (
    <div className={styles.tags}>
      <TextTag tag={tags?.contentType} />
      <TagsIcons
        tags={tags}
        hideTags={{ targetAudience: true, contentType: true }}
        className={styles.tagIcons}
      />
    </div>
  );

  return (
    <Popup isOpen={true} title={lang.HEADER}>
      {renderTags()}
      {renderOwner()}
      {ownerByIdRender()}
      <Contributors contributors={contributors} />

      <div className={styles.data}>
        {(!!expires_at || !!expiresAt) && (
          <div className={styles.detail}>
            <h6>{lang.EXPIRE_DATA}</h6>
            <div className={styles.expiryDate}>
              <span className={expiryStyle}>{formatLongDate(expiryDate)}</span>
              {isShowExclamationMark && (
                <ExclamationMark color={theme.contentExpired} className={styles.exclamationMark} />
              )}
            </div>
          </div>
        )}

        {!!lastUpdated && (
          <div className={styles.detail}>
            <h6>{lang.LAST_UPDATE}</h6>
            <div className={styles.expiryDate}>
              <span>{formatLongDate(lastUpdated)}</span>
            </div>
          </div>
        )}
      </div>

      <div className={styles.detail}>
        <h6>{lang.PARENTS_PATH}</h6>
        {parents.map(category => (
          <Breadcrumbs
            wrapperStyle={styles.breadcrumbs}
            pathParts={category.pathParts}
            title={category.title}
            key={category.id}
            onCurrentClick={() => onNavigateToCategory(category.id)}
            hideRoot
          />
        ))}
      </div>

      <Button onClick={close}>{lang.DISMISS}</Button>
    </Popup>
  );
});

KnowledgeInfoComponent.defaultProps = {
  owner: {},
  itemId: null,
  updatedAt: '',
  file_updated_at: null,
  fileUpdatedAt: null,
  expires_at: '',
  expiresAt: '',
  ownerObject: {},
  lang: Lang.INFORMATION_MODAL,
  parents: [],
  knowledgeSetItemDetails: () => {},
  knowledgeSelectItem: () => {},
};

export { KnowledgeInfoComponent };
