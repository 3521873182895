import React from 'react';
import classnames from 'classnames';
import { Select } from 'antd';
import { Tag } from '../tag';
import styles from './TagInput.module.scss';

const { Option } = Select;

const TagInput = React.memo(
  ({
    name,
    error,
    touched,
    wrapperClass,
    wrapperStyle,
    inputStyle,
    onChange,
    value,
    options,
    placeholder,
    mode,
    optionsRender,
    ...props
  }) => {
    const onChangeLocal = value => {
      onChange(name, value);
    };

    const renderOptions = () => {
      if (optionsRender) {
        return options.map(({ id, value }) => (
          <Option value={id} key={id}>
            {value}
          </Option>
        ));
      }

      return options.map(key => <Option key={key.toString(36)}>{key}</Option>);
    };

    return (
      <div className={styles.root}>
        <div className={classnames(styles[wrapperStyle], wrapperClass)}>
          <Select
            bordered={false}
            mode={mode}
            defaultValue={value}
            tagRender={Tag}
            style={{ width: '100%' }}
            placeholder={placeholder}
            onChange={onChangeLocal}
            {...props}
          >
            {renderOptions()}
          </Select>
        </div>
        {Array.isArray(error) ? (
          error.map((err, index) => (
            <span key={index} className={styles.error}>
              {err}
            </span>
          ))
        ) : (
          <span className={styles.error}>{error}</span>
        )}
      </div>
    );
  },
);

TagInput.defaultProps = {
  error: null,
  onChange: () => {},
  name: null,
  wrapperClass: null,
  wrapperStyle: 'greyWrapper',
  inputStyle: 'user',
  options: [],
  placeholder: '',
  mode: 'tags',
  optionsRender: false,
};

export { TagInput };
