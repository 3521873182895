import { connect } from 'react-redux';
import { KnowledgeEditorPopupComponent } from './KnowledgeEditor.component';
import {
  createKnowledgeItem,
  updateKnowledgeItem,
} from 'shared/store/actions/knowledge.items.actions';
import { getLocationsOfKnowledge } from 'shared/store/selectors/knowledge.selector';

const mapStateToProps = ({ knowledge, lang }) => ({
  locations: getLocationsOfKnowledge(knowledge) || [],
  lang: lang.KNOWLEDGE_EDITOR,
  langNotification: lang.COMMUNICATION_CENTER,
});

export const KnowledgeEditorPopup = connect(mapStateToProps, {
  createKnowledgeItem,
  updateKnowledgeItem,
})(KnowledgeEditorPopupComponent);
