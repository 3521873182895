import {
  ASSET_TYPES,
  ASSET_TYPES_BY_FILE_EXTENSION,
  ASSET_TYPES_LIMITS,
} from 'shared/constants/assets.constants';
import { FileWithPreview } from 'src/components/core';

const useFileValidation = ({
  file,
  isSmartFormat = false,
  error,
}: {
  file: FileWithPreview | null;
  isSmartFormat: boolean;
  error?: string | null;
}) => {
  const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase();
  const fileType = fileExtension ? ASSET_TYPES_BY_FILE_EXTENSION[fileExtension] : undefined;
  const isVideo = fileType === ASSET_TYPES.VIDEO;

  const fileValidationError = (() => {
    if (error) {
      return error === 'file-invalid-type' ? ('unsupported-file' as const) : error;
    }

    if (!file) {
      return;
    }

    if (!fileType) {
      return 'unsupported-file' as const;
    }

    if (isSmartFormat && fileType !== ASSET_TYPES.DOCUMENT && fileType !== ASSET_TYPES.PDF) {
      return 'unsupported-file-for-smart-format' as const;
    }

    const assetTypeSizeLimit = ASSET_TYPES_LIMITS[fileType];

    if (file.size > assetTypeSizeLimit) {
      if (isVideo) {
        return 'video-too-big' as const;
      } else {
        return 'file-too-big' as const;
      }
    }
  })();

  return {
    fileValidationError,
    fileExtension,
  };
};

export { useFileValidation };
