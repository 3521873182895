import React from 'react';
import { Switch, useHistory } from 'react-router';
import { AuthRoute } from '../router/AuthRoute';
import { useSelector } from 'react-redux';
import {
  ROUTES,
  ACCOUNT_ADMIN_PERMISSIONS_GROUP,
  COLLABORATOR_PERMISSIONS_GROUP,
  ROOT_PERMISSIONS_GROUP,
} from 'shared/constants';
import { PrivateRoute } from '../router/PrivateRoute';
import {
  SignInPage,
  VerificationPage,
  NotAllowedPage,
  OauthCallback,
} from '../../pages/authentication';
import { setNavigate } from 'shared/services/navigation.service';
import {
  FavoritesPage,
  KnowledgePage,
  SearchPage,
  LabsPage,
  SwitchOrganizationPage,
  UserManagementPage,
  OwnersManagementPage,
  AdmissionPage,
  CommunicationCenterPage,
  DashboardPage,
  KnowledgeContentPage,
  UserProfilePage,
  SuggestContentPage,
  HubComingSoonPage,
} from '../../pages/application';
import ScrollRestoration from '../core/scroll-restoration';
import { ContactsPage } from '../../pages/application/contacts';
import {
  isContactsFeatureEnabled,
  isRotationsEnabled,
} from 'shared/store/selectors/features.selector';
import { Route } from '../router/Route.component';
import { AppLayout } from '../app-layout';
import { RotationsPage } from 'src/pages/application/rotations/rotations.page.component';
import { hasPermissionsToAccessKnowledgeHub } from 'shared/store/selectors/iam.selector';
import { SettingsPage } from 'src/pages/application/settings';

const Router = React.memo(() => {
  const history = useHistory();

  const isContactsEnabled = useSelector(isContactsFeatureEnabled);
  const isRotationsEnabledState = useSelector(isRotationsEnabled);
  const isKnowledgeHubVisible = useSelector(hasPermissionsToAccessKnowledgeHub);

  React.useEffect(() => {
    setNavigate(history);
  }, [history]);

  return (
    <Switch>
      <AuthRoute exact path={ROUTES.WELCOME} component={SignInPage} />
      <AuthRoute exact path={ROUTES.OTP_VERIFICATION} component={VerificationPage} />

      <AuthRoute exact path={ROUTES.NOT_ALLOWED} component={NotAllowedPage} />

      {/* OAuth callback for authentication process */}
      <Route exact path={ROUTES.OAUTH_CALLBACK} component={OauthCallback} />

      <PrivateRoute path={ROUTES.ROOT}>
        <ScrollRestoration />
        <AppLayout>
          <PrivateRoute exact path={[ROUTES.ROOT, ROUTES.HOME]} component={DashboardPage} />
          <PrivateRoute exact path={ROUTES.SUGGEST_CONTENT} component={SuggestContentPage} />
          <PrivateRoute exact path={ROUTES.SEARCH} component={SearchPage} />
          <PrivateRoute exact path={ROUTES.FAVORITES} component={FavoritesPage} />
          {isContactsEnabled && (
            <PrivateRoute exact path={ROUTES.CONTACTS} component={ContactsPage} />
          )}
          <PrivateRoute exact path={ROUTES.LABS} component={LabsPage} />
          <PrivateRoute
            exact
            permissions={ROOT_PERMISSIONS_GROUP}
            path={ROUTES.SETTINGS}
            component={SettingsPage}
          />
          <PrivateRoute exact path={ROUTES.LABS_CATEGORIES} component={LabsPage} />
          <PrivateRoute exact path={ROUTES.ADMISSION} component={AdmissionPage} />
          <PrivateRoute exact path={ROUTES.ADMISSION_CATEGORIES} component={AdmissionPage} />
          <PrivateRoute exact path={ROUTES.USER_PROFILE} component={UserProfilePage} />
          <PrivateRoute
            exact
            path={ROUTES.SWITCH_ORGANIZATION}
            component={SwitchOrganizationPage}
          />
          <PrivateRoute
            exact
            permissions={ACCOUNT_ADMIN_PERMISSIONS_GROUP}
            path={ROUTES.USERS_MANAGEMENT}
            component={UserManagementPage}
          />
          <PrivateRoute
            exact
            permissions={ACCOUNT_ADMIN_PERMISSIONS_GROUP}
            path={ROUTES.OWNERS_MANAGEMENT}
            component={OwnersManagementPage}
          />
          <PrivateRoute
            exact
            permissions={COLLABORATOR_PERMISSIONS_GROUP}
            path={ROUTES.COMMUNICATION_CENTER}
            component={CommunicationCenterPage}
          />
          {isRotationsEnabledState && (
            <PrivateRoute exact path={ROUTES.ROTATIONS} component={RotationsPage} />
          )}

          {/* Knowledge routes */}
          <PrivateRoute exact path={ROUTES.CONTENT_ITEM} component={KnowledgePage} />
          <PrivateRoute exact path={ROUTES.KNOWLEDGE} component={KnowledgePage} />
          <PrivateRoute exact path={ROUTES.KNOWLEDGE_ITEMS} component={KnowledgeContentPage} />

          {isKnowledgeHubVisible && (
            <PrivateRoute path={ROUTES.KNOWLEDGE_HUB} component={HubComingSoonPage} />
          )}
        </AppLayout>
      </PrivateRoute>
    </Switch>
  );
});

export { Router };
