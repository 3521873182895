export const getContactItemById = ({ contacts, usersManagement }, itemId) => {
  const contact = contacts?.contactsById?.[itemId];
  if (contact) {
    return contact;
  }

  const user = usersManagement?.list?.find(item => item.id === itemId);
  if (user) {
    return { ...user, isUser: true, firstName: user.firstName || user.email };
  }

  return null;
};

export const getContacts = ({ contacts, usersManagement }) => {
  const contactList = Object.values(contacts?.contactsById ?? []);
  const userWithIsUserFlag =
    usersManagement?.list?.map(user => ({
      ...user,
      isUser: true,
      firstName: user.firstName || user.email,
    })) ?? [];

  return [...contactList, ...userWithIsUserFlag].sort(
    (a, b) => a.firstName?.localeCompare(b.firstName) || a.lastName?.localeCompare(b.lastName),
  );
};

export const getContributors = ({ usersManagement, knowledge }) => {
  const contributorsFromAllKnowledgeItems = knowledge?.items
    ?.filter(item => item.contributors && item.contributors.length > 0)
    .flatMap(item => item.contributors);

  const uniqueContributorsMap = new Map();
  contributorsFromAllKnowledgeItems.forEach(contributor => {
    // Use contributorId as key in the map to ensure uniqueness
    if (contributor.contributorId && !contributor.userId) {
      uniqueContributorsMap.set(contributor.contributorId, contributor);
    }
  });
  // Get the values from the map which are unique contributors
  const uniqueContributors = Array.from(uniqueContributorsMap.values());

  const users = usersManagement?.list ?? [];

  return [...users, ...uniqueContributors].map(user => ({
    ...user,
    firstName: user?.firstName || user?.displayName || user?.email || '',
  }));
};

export const getEmailsForContributors =
  (contributors = []) =>
  ({ usersManagement }) => {
    return contributors.map(contributor => {
      const user = usersManagement?.list?.find(user => user.id === contributor.userId);
      if (user) {
        return {
          ...contributor,
          email: user.email,
        };
      }
      return contributor;
    });
  };
