export const PROFESSIONS = {
  MEDICAL_DOCTOR: 'MEDICAL_DOCTOR',
  NURSE: 'NURSE',
  NURSE_PRACTITIONER: 'NURSE_PRACTITIONER',
  ANESTHESIA_ASSISTANT: 'ANESTHESIA_ASSISTANT',
  PHYSIOTHERAPIST: 'PHYSIOTHERAPIST',
  PHARMACIST: 'PHARMACIST',
  CAREGIVER: 'CAREGIVER',
  PARAMEDIC: 'PARAMEDIC',
  MEDICAL_DIRECTOR: 'MEDICAL_DIRECTOR',
  ADMINISTRATIVE_ASSITANT: 'ADMINISTRATIVE_ASSITANT',
  MIDWIFE: 'MIDWIFE',
  OPERATING_ROOM_INSTRUMENTALIST: 'OPERATING_ROOM_INSTRUMENTALIST',
  ANESTHESIA_TECHNICIAN: 'ANESTHESIA_TECHNICIAN',
  PHYSICIAN_ASSISTANT: 'PHYSICIAN_ASSISTANT',
  LOGISTICS_OPERATIONS: 'LOGISTICS_OPERATIONS',
  SOCIAL_WORKER: 'SOCIAL_WORKER',
  CARDIOTECHNICIAN: 'CARDIOTECHNICIAN',
  CRNA: 'CRNA',
  CAA: 'CAA',
};

export const ROLES_DOCTOR = {
  MEDICAL_STUDENT: 'MEDICAL_STUDENT',
  INTERN: 'INTERN',
  RESIDENT: 'RESIDENT',
  CHIEF_RESIDENT: 'CHIEF_RESIDENT',
  FELLOW: 'FELLOW',
  ATTENDING_PHYSICIAN: 'ATTENDING_PHYSICIAN',
  CHIEF_PHISICIAN: 'CHIEF_PHISICIAN',
  DEPARTMENT_CHAIR: 'DEPARTMENT_CHAIR',
  BASIC_SCIENCE_FACULTY: 'BASIC_SCIENCE_FACULTY',
  FACULTY_PHISICIAN: 'FACULTY_PHISICIAN',
};

export const ROLES_NURSE = {
  STUDENT_NURSE: 'STUDENT_NURSE',
  TRAINEE_NURSE: 'TRAINEE_NURSE',
  CERTIFIED_NURSE: 'CERTIFIED_NURSE',
  SPECIALISED_NURSE: 'SPECIALISED_NURSE',
  HEAD_NURSE: 'HEAD_NURSE',
  CHARGE_NURSE: 'CHARGE_NURSE',
  NURSE_SUPERVISOR: 'NURSE_SUPERVISOR',
  NURSE_EDUCATOR: 'NURSE_EDUCATOR',
};

export const ROLES_PARAMEDIC = {
  STUDENT: 'STUDENT',
  TRAINEE_PARAMEDIC: 'TRAINEE_PARAMEDIC',
  CERTIFIED_PARAMEDIC: 'CERTIFIED_PARAMEDIC',
  HEAD_PARAMEDIC: 'HEAD_PARAMEDIC',
};

export const ROLES_LOGISTICS = {
  SUPPLY_CHAIN: 'SUPPLY_CHAIN',
  EQUIPMENT_MANAGEMENT: 'EQUIPMENT_MANAGEMENT',
  PERIOPERATIVE_STAFF: 'PERIOPERATIVE_STAFF',
};

export const ROLES_CRNA = {
  STAFF_CRNA: 'STAFF_CRNA',
  CHIEF_CRNA: 'CHIEF_CRNA',
  CHIEF_ANESTHETIST: 'CHIEF_ANESTHETIST',
  SRNA: 'SRNA',
};

export const ROLES_CAA = {
  CHIEF_ANESTHETIST: 'CHIEF_ANESTHETIST',
  STAFF_CAA: 'STAFF_CAA',
  CHIEF_CAA: 'CHIEF_CAA',
};

export const ROLES_ALL = {
  ...ROLES_DOCTOR,
  ...ROLES_NURSE,
  ...ROLES_PARAMEDIC,
  ...ROLES_LOGISTICS,
  ...ROLES_CRNA,
  ...ROLES_CAA,
};

export const TRAINING_LEVELS_MEDICAL_STUDENT = {
  MED1: 'MED1',
  MED2: 'MED2',
  MED3: 'MED3',
  MED4: 'MED4',
  MED5: 'MED5',
  MED6: 'MED6',
};
export const TRAINING_LEVELS_RESIDENT = {
  CA_1: 'CA_1',
  CA_2: 'CA_2',
  CA_3: 'CA_3',
  PGY_1: 'PGY_1',
  PGY_2: 'PGY_2',
  PGY_3: 'PGY_3',
  PGY_4: 'PGY_4',
  PGY_5: 'PGY_5',
  PGY_6: 'PGY_6',
  PGY_7: 'PGY_7',
};

export const TRAINING_LEVELS_ALL = {
  ...TRAINING_LEVELS_MEDICAL_STUDENT,
  ...TRAINING_LEVELS_RESIDENT,
};

export const USER_TAGS_KEYS = {
  PROFESSION: 'profession',
  ROLE: 'role',
  LEVEL_OF_TRAINING: 'levelOfTraining',
  ROTATION: 'rotation',
  DEPARTMENT: 'department',
  USERS: 'users',
};

export const LANGUAGES = {
  EN: 'EN',
  FR: 'FR',
  DE: 'DE',
  IT: 'IT',
};
