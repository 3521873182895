import { ORGANIZATIONS_LOGO_URL } from 'shared/constants';
import { LogoWhite } from 'src/images';
import { Dropdown, Menu, Modal } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faLock, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import styles from './switch-organization-dropdown.module.scss';
import classnames from 'classnames';
import OAuthConfirm from './oauth-confirmation-modal';
import OTPConfirm from './otp-confirmation-modal';
import authenticationServiceApi from 'shared/api/authentication.service.api';

const SwitchOrganizationDropdownComponent = ({
  activeOrganization,
  organizations = [],
  switchOrganization,
  isSwitchOrganizationAvailable,
  lang,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [oAuthModalOpen, setOAuthModalOpen] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);

  const handleVisibleChange = visible => {
    setDropdownVisible(visible);
  };

  const items = useMemo(
    () =>
      organizations
        .map(org => ({
          label: org.name,
          key: org.id,
          active: org.activated,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [organizations],
  );

  const menuStyle = {
    marginLeft: '24px',
    marginTop: '0px',
    width: '200px',
    fontSize: '15px',
    maxHeight: '36rem',
    maxWidth: '18rem',
    overflow: 'auto',
  };

  const menuItemStyle = {
    color: '#2A48B0',
    borderRadius: '0',
    padding: '8px 12px',
    display: 'flex',
    backgroundColor: undefined,
  };

  const doSwitchOrganization = useCallback(() => {
    if (organization.activated) {
      switchOrganization({ id: organization.id });
      return;
    }

    if (organization.auth.type === 'OAuth') {
      setOAuthModalOpen(true);
      return;
    }
  }, [switchOrganization, organization]);

  React.useEffect(() => {
    if (organization) {
      doSwitchOrganization();
    }
  }, [organization, doSwitchOrganization]);

  const onSubmitEmail = async email => {
    return await authenticationServiceApi.activateOTPAccount({ email });
  };

  const onVerifyCode = async ({ email, code }) => {
    return await authenticationServiceApi.verifyActivateAccount({ email, code });
  };

  return (
    <>
      <Modal
        open={oAuthModalOpen}
        onCancel={() => {
          setOAuthModalOpen(false);
          setOrganization(organizations.find(org => org.id === activeOrganization.id));
        }}
        width={700}
        footer={null}
        centered
        maskClosable={false}
        destroyOnClose
      >
        <OAuthConfirm organization={organization} />
      </Modal>
      <Modal
        open={otpModalOpen}
        onCancel={() => setOtpModalOpen(false)}
        width={700}
        footer={null}
        centered
        maskClosable={false}
        destroyOnClose
      >
        <OTPConfirm submitEmailCallback={onSubmitEmail} verifyCodeCallback={onVerifyCode} />
      </Modal>
      <Dropdown
        overlay={
          <Menu
            style={menuStyle}
            selectable
            defaultSelectedKeys={activeOrganization.id}
            selectedKeys={activeOrganization.id}
          >
            {items.map(({ key, label, active = true }) => (
              <Menu.Item
                key={key}
                onClick={() => {
                  setOrganization(organizations.find(org => org.id === key));
                  handleVisibleChange(false);
                }}
                style={{
                  ...menuItemStyle,
                  borderBottom: '1px solid #EEF3FF',
                }}
              >
                {!active && <FontAwesomeIcon icon={faLock} className={styles.menuIcon} />}
                {label}
              </Menu.Item>
            ))}
            <Menu.Item
              key="add"
              onClick={() => {
                setOtpModalOpen(true);
              }}
              style={{ ...menuItemStyle, fontWeight: 'bold' }}
            >
              <FontAwesomeIcon icon={faUserPlus} className={styles.menuIcon} />
              {lang.MENU_TITLE}
            </Menu.Item>
          </Menu>
        }
        trigger={'hover'}
        open={dropdownVisible}
        onOpenChange={handleVisibleChange}
      >
        <div
          className={classnames([
            styles.logoWrapper,
            isSwitchOrganizationAvailable ? styles.hoverActive : null,
          ])}
        >
          {activeOrganization.logo ? (
            <img
              src={ORGANIZATIONS_LOGO_URL + 'web/' + activeOrganization.logo}
              alt={activeOrganization.name}
              className={styles.logo}
            />
          ) : (
            <LogoWhite className={styles.logo} />
          )}
          <FontAwesomeIcon
            icon={faAngleDown}
            className={classnames([
              styles.logoIcon,
              dropdownVisible ? styles.activeLogoIcon : null,
            ])}
          />
        </div>
      </Dropdown>
    </>
  );
};

export { SwitchOrganizationDropdownComponent };
