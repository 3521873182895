export const getSelectedWorkspace = ({ workspace }) => {
  return workspace.list.find(w => w.id === workspace.selected);
};

export const getOperationalData = ({ workspace, organization }) => {
  if (workspace.list.length === 0) {
    return { calendarUrl: organization?.calendarUrl, scheduleUrls: organization?.scheduleUrls };
  }

  return getSelectedWorkspace({ workspace })?.operational;
};

export const getSelectedWorkspaceId = ({ workspace }) => {
  return workspace?.selected ?? getWorkspaces({ workspace })?.[0]?.id;
};

export const getWorkspaces = ({ workspace }) => {
  return workspace.list;
};

export const getWorkspaceById =
  id =>
  ({ workspace }) => {
    return id ? workspace?.list?.find(w => w.id === id) : null;
  };

export const isThereOnlyOneWorkspace = ({ workspace }) => {
  return workspace?.list?.length === 1;
};
