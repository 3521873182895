import React from 'react';
import { DATA_KEYS, OFFICIAL_SITE_LINK } from 'shared/constants';
import { AuthPage } from '../auth-page-wrapper';
import { useFormik } from 'formik';
import styles from './sign-in.module.scss';
import { loginSchema } from 'shared/schemas/user.schemas';
import UserService from 'shared/services/user.service';
import { TextInput } from 'src/components/design-system/forms';
import { Button } from 'src/components/design-system/buttons';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { useLocation } from 'react-router-dom-v5-compat';
import { useOauthConfig } from '../hooks';
import { OAuthFooter } from '../oauth';
const { WELCOME_SCREEN } = DATA_KEYS;

export function SignInPage() {
  const [error, setError] = React.useState(null);
  const [loading, setLoadingState] = React.useState(false);
  const { state } = useLocation();
  const { doSignInWithOauth, hasSocialLogin } = useOauthConfig();

  React.useEffect(() => {
    async function signInWithOauth() {
      if (state?.query?.code) {
        setError('');
        setLoadingState(true);

        const res = await doSignInWithOauth(state);

        if (res.error) {
          setError(res.error);
        }

        setLoadingState(false);
      }
    }
    signInWithOauth();
  }, [state, doSignInWithOauth]);

  const lang = useSelector(getLang('WELCOME_SCREEN'));

  const signInAction = React.useCallback(async ({ email }) => {
    setError('');
    setLoadingState(true);

    let res = await UserService.signin({ email });

    setLoadingState(false);

    if (res?.error) {
      setError(res.error);
    }
  }, []);

  const { handleChange, handleBlur, handleSubmit, values, errors, touched, isValid } = useFormik({
    validationSchema: loginSchema,
    initialValues: { email: '' },
    onSubmit: signInAction,
  });

  return (
    <AuthPage title={lang.HEADER} content={lang.CONTENT}>
      <form onSubmit={handleSubmit}>
        <TextInput
          data-key={WELCOME_SCREEN.EMAIL_INPUT}
          type="email"
          name="email"
          value={values.email}
          error={touched.email && errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus={true}
          size="middle"
          placeholder={lang.EMAIL_PLACEHOLDER}
          disabled={loading}
        />

        <Button
          data-key={WELCOME_SCREEN.SUBMIT_BUTTON}
          disabled={!isValid}
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          {lang.SEND_VERIFICATION_CODE}
        </Button>

        <span className={styles.error}>{error}</span>

        <p className={styles.notMember}>
          {lang.NOT_A_MEMBER}
          <a href={OFFICIAL_SITE_LINK} className={styles.link}>
            {lang.WEB_LINK}
          </a>
        </p>

        {hasSocialLogin && <OAuthFooter loading={loading} />}
      </form>
    </AuthPage>
  );
}
