import React from 'react';
import styles from './Dashboard.module.scss';
import { Lang, DATA_KEYS } from 'shared/constants';
import { Loader } from '../../../components/core';
import { KnowledgeItemsWidget } from './components/knowledge-items-widget/';
import { NewsFeedList } from '../../../components/news-feed';
import { CalendarWidget, CalendarListWidget } from '../../../components/calendar-widget';
import { RotationsWidget } from './components/rotations-widget.component';
import { SuggestKnowledgeItemWidget } from './components/suggest-knowledge-widget';
import { DashboardBanner } from './components/dashboard-banner.component';
import { useLocation } from 'react-router-dom-v5-compat';
import { useOauthConfig } from '../../../pages/authentication/hooks';
import { notification } from 'antd';
import { ExpiredKnowledgeWidget } from './components/expired-knowledge-widget';
import { useSelector } from 'react-redux';
import { actions, RecentUpdatedWidget } from './components/recent-updated-widget';
const { DASHBOARD } = DATA_KEYS;

const DashboardPageComponent = React.memo(function DashboardPageComponent({
  lang,
  addAccLang,
  itemsOpenEventsForOrganization,
  itemsOpenEventsForUser,
  expiredItems,
  isReady,
  adminMode,
  calendarUrl,
  scheduleUrlMap,
  isAdmin = false,
}) {
  const { doActivateAccount } = useOauthConfig();
  const { state } = useLocation();
  const ownerId = useSelector(state => state.auth.user?.id);
  const [api, contextHolder] = notification.useNotification();

  React.useEffect(() => {
    (async () => {
      if (state?.query?.code) {
        const res = await doActivateAccount(state);

        if (res.error) {
          api.error({
            message: addAccLang.CONTACT_SUPPORT,
            duration: 0,
            placement: 'bottomRight',
          });
        }
      }
    })();
  }, [state, api, addAccLang.CONTACT_SUPPORT, doActivateAccount]);

  if (!isReady) {
    return <Loader />;
  }

  if (adminMode) {
    return (
      <div className={styles.dashboardWrapper}>
        <div className={styles.widgets} data-key={DASHBOARD.EXPIRED_ITEMS}>
          {isAdmin && (
            <ExpiredKnowledgeWidget
              title={lang.TITLE_ALL_EXPIRED_ITEMS}
              items={expiredItems}
              allowEditExpired
            />
          )}
          <KnowledgeItemsWidget
            title={lang.TITLE_EXPIRED_ITEMS}
            items={expiredItems[ownerId]}
            paginated
            allowEditExpired
          />
        </div>
        <div className={styles.newsFeed}>
          <NewsFeedList />
        </div>
      </div>
    );
  }

  return (
    <>
      {contextHolder}
      <DashboardBanner />
      <div className={styles.dashboardWrapper}>
        <div className={styles.widgets}>
          {isAdmin && (
            <ExpiredKnowledgeWidget
              title={lang.TITLE_ALL_EXPIRED_ITEMS}
              items={expiredItems}
              allowEditExpired
            />
          )}
          <SuggestKnowledgeItemWidget />
          <RotationsWidget />
          {/* <CalendarWidget url={scheduleUrl} title={lang.TITLE_SCHEDULE} />  // TEMP: qgenda iframe block */}
          <CalendarListWidget title={lang.TITLE_SCHEDULE} list={scheduleUrlMap} />
          <CalendarWidget url={calendarUrl} title={lang.TITLE_CALENDAR} />
          <RecentUpdatedWidget
            widgetKey="added_items"
            key="added_items"
            title={lang.TITLE_RECENTLY_ADDED_ITEMS}
            action={actions.RECENTLY_CREATED_ITEMS}
          />
          <KnowledgeItemsWidget
            key="items_open_events_user"
            title={lang.TITLE_MOST_VIEWED_ITEMS_BY_USER}
            items={itemsOpenEventsForUser}
          />
        </div>
        <div className={styles.newsFeed}>
          <NewsFeedList />
          <RecentUpdatedWidget
            key="updated_items"
            widgetKey="updated_items"
            title={lang.TITLE_RECENTLY_UPDATED_ITEMS}
            action={actions.RECENTLY_UPDATED_ITEMS}
          />

          <KnowledgeItemsWidget
            key="items_open_events_organization"
            title={lang.TITLE_MOST_VIEWED_ITEMS_BY_ORGANIZATION}
            items={itemsOpenEventsForOrganization}
          />
        </div>
      </div>
    </>
  );
});

DashboardPageComponent.defaultProps = {
  lang: Lang.DASHBOARD,
  itemsOpenEventsForOrganization: [],
  itemsOpenEventsForUser: [],
  expiredItems: [],
  isReady: false,
  adminMode: false,
};

export { DashboardPageComponent };
