import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InformationIcon, SelectIcon, DeleteIcon, DirectoryIcon, CrossIcon } from 'src/images';
import { SingleTagIcon } from 'src/components/tags/tagsIcons';
import { Knowledge } from './knowledge.component';
import styles from './knowledge.module.scss';
import { knowledgeSetItemDetails } from 'src/store/actions/knowledgeViewer.actions';
import { colorPalette } from 'shared/constants/theme';
import { updateKnowledgeItem } from 'shared/store/actions/knowledge.items.actions';
import { getLang } from 'shared/store/selectors/lang.selector';
import { Tooltip } from 'src/components/core';
import classNames from 'classnames';
import { haveEditPermissions } from 'shared/store/selectors/knowledge.selector';

const KnowledgeWrapperComponent = ({
  onClickEvent = () => {},
  item = {},
  linkOwnerIds = [],
  categoryId = null,
  selectItem = () => {},
  removeItem = () => {},
  search = false,
  selected = false,
  editMode = false,
  adminMode = false,
  onDelete = () => {},
  onUpdate = () => {},
  onMove = () => {},
  linkedItem = false,
  hideFavorites = false,
  ...props
}) => {
  const dispatch = useDispatch();

  const hasEditPermission = useSelector(state => haveEditPermissions(state, item));

  const lang = useSelector(getLang('ACTION_BUTTON_TOOLTIPS'));

  const openInformation = useCallback(
    e => {
      e.stopPropagation();
      dispatch(knowledgeSetItemDetails(item?.id, linkOwnerIds, categoryId));
    },
    [item, dispatch, linkOwnerIds, categoryId],
  );

  const select = useCallback(
    e => {
      e.stopPropagation();
      selectItem(item);
    },
    [item, selectItem],
  );

  const unselect = useCallback(
    e => {
      e.stopPropagation();
      removeItem(item);
    },
    [item, removeItem],
  );

  const onMoveAction = useCallback(
    e => {
      e.stopPropagation();
      if (!hasEditPermission) {
        return;
      }
      onMove({ id: item.id, linkOwnerIds, ownerIds: item.ownerIds });
    },
    [hasEditPermission, onMove, item, linkOwnerIds],
  );

  const onDeleteAction = useCallback(
    e => {
      e.stopPropagation();
      if (!hasEditPermission) {
        return;
      }
      onDelete({ id: item.id, title: item.title });
    },
    [hasEditPermission, onDelete, item],
  );

  const onExternalUpdateAction = useCallback(
    async dataToUpdate => {
      await dispatch(updateKnowledgeItem({ ...item, ...dataToUpdate }));
    },
    [item, dispatch],
  );

  const actionButtons = () => {
    if (editMode) {
      return (
        <>
          {item?.tags?.specialty && <SingleTagIcon tag={item?.tags?.specialty} />}
          <Tooltip title={lang.INFO}>
            <button type="button" onClick={openInformation} className={styles.buttonWrapper}>
              <InformationIcon
                color={colorPalette.primaryMain5}
                className={styles.informationIcon}
              />
            </button>
          </Tooltip>
          {search && (
            <Tooltip title={lang.ADD}>
              <button type="button" onClick={select} className={styles.buttonWrapper}>
                <img src={SelectIcon} className={styles.informationIcon} alt="Select" />
              </button>
            </Tooltip>
          )}
          {selected && (
            <Tooltip title={lang.REMOVE}>
              <button type="button" onClick={unselect} className={styles.buttonWrapper}>
                <CrossIcon color={colorPalette.primaryMain5} className={styles.unselectIcon} />
              </button>
            </Tooltip>
          )}
        </>
      );
    }
    return (
      <>
        <Tooltip title={hasEditPermission ? lang.DELETE : lang.NOT_ALLOWED_TO_EDIT} placement="top">
          <button
            type="button"
            onClick={onDeleteAction}
            className={styles.buttonWrapper}
            disabled={!hasEditPermission}
          >
            <DeleteIcon
              className={classNames(styles.deleteIcon, hasEditPermission ? '' : styles.disabled)}
            />
          </button>
        </Tooltip>

        <Tooltip title={hasEditPermission ? lang.MOVE : lang.NOT_ALLOWED_TO_EDIT} placement="top">
          <button
            type="button"
            onClick={onMoveAction}
            className={styles.buttonWrapper}
            disabled={!hasEditPermission}
          >
            <DirectoryIcon className={styles.changeDirectory} />
          </button>
        </Tooltip>
      </>
    );
  };

  if (!linkedItem) {
    return (
      <Knowledge
        key={item.id}
        {...item}
        adminMode={adminMode}
        deleteKnowledge={onDelete}
        editKnowledge={onUpdate}
        moveKnowledgeAction={onMove}
        openInformation={openInformation}
        actionButtons={actionButtons}
        hideFavorites={hideFavorites}
        externalUpdateAction={onExternalUpdateAction}
        onClickEvent={onClickEvent}
        lang={lang}
        {...props}
      />
    );
  }
  return (
    <Knowledge
      key={item.id}
      {...item}
      actionButtons={actionButtons}
      adminMode={adminMode}
      draggable={!editMode}
      hideFavorites={hideFavorites}
      openInformation={openInformation}
      onClickEvent={onClickEvent}
      lang={lang}
      {...props}
    />
  );
};

export { KnowledgeWrapperComponent };
