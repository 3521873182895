import React from 'react';
import { CactusNoContent } from '../../../images';
import styles from './NoContent.module.scss';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';

const NoContent = ({ text, className }) => {
  const lang = useSelector(getLang('GENERAL'));
  return (
    <div className={className || styles.root}>
      <h4>{text || lang.NO_ITEMS_FOUND}</h4>
      <img src={CactusNoContent} className={styles.cactus} alt={text || lang.NO_ITEMS_FOUND} />
    </div>
  );
};

NoContent.defaultProps = {
  text: '',
  className: null,
};

export { NoContent };
