import { LAB_CREATE_ITEM, LAB_DELETE_ITEM, LAB_UPDATE_ITEM, LAB_UPDATE_ITEMS_ORDER } from '.';
import labServiceApi from '../../api/lab.service.api';

export const createLabItem = data => async (dispatch, getState) => {
  const workspaceId = getState().workspace.selected;

  let res = await labServiceApi.createValue({ data, workspaceId });

  if (res.error) {
    throw new Error(res.data.message);
  }

  let { item } = res.data;

  dispatch({
    type: LAB_CREATE_ITEM,
    payload: {
      item,
      categoryId: data.categoryId,
    },
  });

  return item;
};

export const createQuickLabItem =
  ({ categoryId, ownerIds, tags }) =>
  async dispatch => {
    return dispatch(createLabItem({ item: { title: '', value: '', ownerIds, tags }, categoryId }));
  };

export const updateLabItem =
  ({ id, item }) =>
  async dispatch => {
    let res = await labServiceApi.updateLabItem({ id, item });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: LAB_UPDATE_ITEM,
      payload: {
        item: { id, ...item },
      },
    });

    return res.data;
  };

export const updateLabItemsOrder =
  ({ id, itemId, index }) =>
  async dispatch => {
    let res = await labServiceApi.updateLabItemsOrder({ id, itemId, index });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: LAB_UPDATE_ITEMS_ORDER,
      payload: { id, itemId, index },
    });
  };

export const deleteLabItem =
  ({ id }) =>
  async dispatch => {
    let res = await labServiceApi.deleteLabItem({ id });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: LAB_DELETE_ITEM,
      payload: {
        id,
      },
    });
  };

export const updateValueOrder =
  ({ id, itemId, index }) =>
  async dispatch => {
    let res = await labServiceApi.updateValueOrder({ id, itemId, index });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: LAB_UPDATE_ITEMS_ORDER,
      payload: { id, itemId, index },
    });
  };
