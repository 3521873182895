export const FEATURES = {
  CONTACTS: 'contacts',
  SUGGEST_CONTENT: 'suggest-content',
  SUGGEST_KEYWORDS: 'suggest-keywords',
  IMPROVED_SEARCH: 'improved-search',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  ROTATIONS: 'rotations',
  KNOWLEDGE_ITEMS_EMBEDDINGS: 'knowledge-items-embeddings',
  AI_ASK_PANDA: 'ai-ask-panda',
  APPROVAL_MARK: 'approval-mark',
  KNOWLEDGE_HUB_SHARED: 'knowledge-hub-shared',
  PERMISSIONS_OWNER_RESTRICTION: 'permissions-owner-restriction',
  KNOWLEDGE_COMMENTS: 'knowledge-comments',
};
