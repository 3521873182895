import { connect } from 'react-redux';
import { fetchUsers, fetchUserStats } from 'shared/store/actions/users-management.actions';
import { RecipientsSelectorComponent } from './recipients-selector.component';

const mapStateToProps = ({ lang, usersManagement }) => ({
  lang: lang.RECIPIENTS_SELECTOR,
  users: usersManagement.list,
  usersStats: usersManagement.stats,
  usersLastUpdated: usersManagement.lastUpdated,
});

const mapDispatchToProps = {
  fetchUsers,
  fetchUserStats,
};

export const RecipientsSelector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecipientsSelectorComponent);
