import React from 'react';
import { LangSelector } from '../../../components/lang-selector';
import styles from './auth-page-wrapper.module.scss';
import { Logo } from 'shared/images/logos';
import { Card, Col, Row } from 'antd';
import { Panda, PandaClosesEyes } from 'shared/images/characters';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getLang } from 'shared/store/selectors/lang.selector';
import { OFFICIAL_SITE_LINK } from 'shared/constants';

const AuthPage = ({ children, pandaCloseEyes = false, title = '', content = '' }) => {
  const lang = useSelector(getLang('AUTH'));
  return (
    <div className={classNames(styles.root, 'new-design-system')}>
      <div className={styles.header}>
        <img src={Logo} alt="C8 Health" className={styles.logo} />
        <a href={OFFICIAL_SITE_LINK} target="_blank" className={styles.link} rel="noreferrer">
          {lang.WEB_LINK}
        </a>
      </div>
      <div className={styles.wrapper}>
        <Row>
          <Col
            span={12}
            offset={6}
            xxl={{ span: 10, offset: 7 }}
            xl={{ span: 14, offset: 5 }}
            lg={{ span: 18, offset: 3 }}
            md={{ span: 20, offset: 2 }}
            xs={{ span: 22, offset: 1 }}
          >
            <Card>
              <Row>
                <Col md={9} xs={0}>
                  <img
                    src={pandaCloseEyes ? PandaClosesEyes : Panda}
                    alt="C8 Health"
                    className={styles.panda}
                  />
                </Col>
                <Col md={13} xs={24}>
                  <h1 className={styles.title}>{title}</h1>
                  <p className={styles.content}>{content}</p>
                  {children}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <LangSelector className={styles.lang} />
    </div>
  );
};

export { AuthPage };
