const { currentEnvironment } = require('../services/Environment');
const { REGIONS } = require('./region.constants');

const backendServicebaseURLsMap = {
  [REGIONS.US]: {
    development: 'http://localhost:3600/',
    staging: 'https://api.staging.app.c8health.com/',
    production: 'https://api.app.c8health.com/',
  },
  [REGIONS.EU]: {
    development: 'http://localhost:3555/',
    staging: 'https://api.staging.eu.c8health.com/',
    production: 'https://api.eu.c8health.com/',
  },
};

const assetsServiceBaseURLsMap = {
  [REGIONS.US]: {
    development: 'http://localhost:4500/',
    staging: 'https://assets.staging.app.c8health.com/',
    production: 'https://assets.app.c8health.com/',
  },
  [REGIONS.EU]: {
    development: 'http://localhost:4555/',
    staging: 'https://assets.staging.eu.c8health.com/',
    production: 'https://assets.eu.c8health.com/',
  },
};

const appUrls = {
  development: 'http://localhost:3000/',
  staging: 'https://staging.app.c8health.com/',
  production: 'https://app.c8health.com/',
};

// BaseURLs
export const getBaseURL = (region = REGIONS.US) => {
  const validRegion = REGIONS[region] ?? REGIONS.US;
  return backendServicebaseURLsMap[validRegion][currentEnvironment()];
};
export const getAssetsServiceBaseURL = (region = REGIONS.US) => {
  const validRegion = REGIONS[region] ?? REGIONS.US;
  return assetsServiceBaseURLsMap[validRegion][currentEnvironment()];
};
export const appURL = appUrls[currentEnvironment()];
export const AUTHENTICATION_SERVICE_URL = 'v1/authentication/';
export const USER_SERVICE_URL = 'v1/user/';
export const ORGANIZATION_SERVICE_URL = 'v1/organizations/';
export const KNOWLEDGE_SERVICE_URL = 'v1/knowledge/';
export const LAB_SERVICE_URL = 'v1/lab/';
export const ADMISSION_SERVICE_URL = 'v1/admission/';
export const OWNERS_SERVICE_URL = 'v1/owners/';
export const COMMUNICATION_SERVICE_URL = 'v1/communication/';
export const FAVORITES_SERVICE_URL = 'v1/user/favorites/';
export const DASHBOARD_SERVICE_URL = 'v1/dashboard/';
export const CONTACT_SERVICE_URL = 'v1/contact/';
export const LABEL_SERVICE_URL = 'v1/labels/';
export const ASSIGNMENT_SERVICE_URL = 'v1/assignment/';
export const ROTATION_SERVICE_URL = 'v1/rotation/';
export const WORKSPACE_SERVICE_URL = 'v1/workspace/';
export const ZEUS_SERVICE_URL = 'v1/zeus/';
export const ANALYTICS_SERVICE_URL = 'v1/analytics/';

export const KNOWLEDGE_STORAGE_URL = 's3.eu-central-1.amazonaws.com/';
